import { IVerifyOtpRequestBody } from '@common/types'

import { api } from '@shared/http-instance'

interface IProps extends IVerifyOtpRequestBody {
  withdrawalId: string
}

export function confirmWithdrawalVerifySms({ withdrawalId, otpCode }: IProps) {
  return api.put<never, never, IVerifyOtpRequestBody>(
    `/withdrawals/${withdrawalId}/verify-otp`,
    { otpCode }
  )
}
