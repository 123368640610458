import { Popper, styled } from '@mui/material'

export const StyledPopper = styled(Popper)(({ theme }) => ({
  minWidth: 200,
  padding: '12px 20px',
  marginTop: '4px !important',
  boxShadow: theme.shadows[3],
  borderColor: theme.palette.primary.dark,
  borderRadius: '6px',
  zIndex: theme.zIndex.drawer + 2,
}))
