import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const TransactionListPageRoute: RouteObject = {
  path: routeNamesPaths.transactions,
  lazy: async () => {
    const { TransactionsList } = await import('./ui')

    return { Component: TransactionsList }
  },
}
