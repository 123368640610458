import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'

import { fetchExchangeDetails } from '../../api'

export function useExchangeDetails(id: string | null) {
  return useQuery({
    queryKey: [queryKeys.exchangeDetails, id],
    queryFn: () => {
      if (!id) {
        return
      }

      return fetchExchangeDetails(id)
    },
    enabled: !!id,
  })
}
