import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IIdvInfo, IUser } from '@common/types'

import { firstElementUp } from '@shared/utils'

interface IAuthStoreState {
  // State
  inProcess: boolean
  user: IUser | null
  userIdvData: IIdvInfo | null

  isUserDetailsLoading: boolean
  setIsUserDetailsLoading: (payload: boolean) => void

  //Mutations
  setInProcess: (payload: boolean) => void
  setUser: (user: IUser | null) => void
  setUserIdvData: (userDetail: IIdvInfo | null) => void

  // Getters
  getUserFullName: () => string
  getUserInitials: () => string
  getEmail: () => string
}

export const useAuthStore = create<IAuthStoreState>()(
  devtools(
    (set, get) => ({
      inProcess: true,
      user: null,
      userIdvData: null,

      isUserDetailsLoading: true,
      setIsUserDetailsLoading: (isUserDetailsLoading) =>
        set({ isUserDetailsLoading }),

      setUser: (user) => set(() => ({ user })),
      setInProcess: (payload) => set(() => ({ inProcess: payload })),
      setUserIdvData: (userIdvData) => set(() => ({ userIdvData })),

      getUserFullName: () => {
        const firstName = get().userIdvData?.firstName
        const lastName = get().userIdvData?.lastName

        if (!firstName || !lastName) {
          return ''
        }

        return `${firstElementUp(firstName.toLowerCase())} ${firstElementUp(
          lastName.toLowerCase()
        )}`
      },
      getUserInitials: () => {
        const firstName = get().userIdvData?.firstName
        const lastName = get().userIdvData?.lastName

        if (!firstName || !lastName) {
          return ''
        }

        return `${firstName[0]}${lastName[0]}`
      },
      getEmail: () => get().user?.email || '',
    }),
    { name: 'auth-store' }
  )
)
