import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TAccountVariant } from '@common/types'

interface IProps {
  amount?: string
  type?: TAccountVariant
  onClick?: () => void
}

export function AmountAndType({ amount, type, onClick }: IProps) {
  const { t } = useTranslation('features')

  const contentText = type ? t(`app-bar.${type}`) : ''

  return (
    <Stack onClick={onClick} alignItems="flex-start">
      {amount !== undefined && (
        <Typography variant="body11" color={'primary.dark'}>
          {`${amount} EUR`}
        </Typography>
      )}

      {type && (
        <Typography variant="body17" color={'common.black'}>
          {contentText.toUpperCase()}
        </Typography>
      )}
    </Stack>
  )
}
