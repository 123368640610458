export const BASE_URL = import.meta.env.VITE_APP_CRYPTO_URL
export const KEYCLOAK_URL = import.meta.env.VITE_APP_KEYCLOAK_URL
export const KEYCLOAK_REALM = import.meta.env.VITE_APP_KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID
export const KEYCLOAK_DEACTIVATE_CLIENT_ID = import.meta.env
  .VITE_APP_KEYCLOAK_DEACTIVATE_CLIENT_ID

export const IS_DEV_MODE =
  import.meta.env.VITE_APP_DEVELOPMENT?.toLowerCase() === 'true'

export const areThereKeycloakCredentials =
  KEYCLOAK_URL && KEYCLOAK_REALM && KEYCLOAK_CLIENT_ID

export const idvLink = import.meta.env.VITE_APP_IDV_URL
