import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { grey } from '@shared/constants'

import { IOperationInfo } from '../../model'

export function OperationInfoItem({ keyName, value }: IOperationInfo) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Stack
      direction={{ md: 'row' }}
      justifyContent="space-between"
      alignItems={{ md: 'center' }}
      spacing={{ xs: 0.5, md: 1.5 }}
      p={{ md: '2px 0' }}
      borderBottom={`1px solid ${grey[200]}`}
    >
      <Typography
        variant={mdAndUp ? 'body10' : 'body8'}
        color={grey[500]}
        minWidth={'max-content'}
        sx={{ wordBreak: 'break-word' }}
      >
        {keyName}
      </Typography>

      <Typography variant={mdAndUp ? 'body3' : 'body1'}>{value}</Typography>
    </Stack>
  )
}
