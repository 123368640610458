import { Button, Stack } from '@mui/material'

export function UiKit() {
  return (
    <Stack direction={'row'}>
      <Stack gap={3} p={4} width={400}>
        <Button variant={'text'} color={'primary'}>
          Button
        </Button>

        <Button variant={'outlined'} color={'primary'}>
          Button
        </Button>

        <Button variant={'contained'} color={'primary'}>
          Button
        </Button>

        <Button variant={'contained'} color={'primary'} disabled>
          Button
        </Button>
      </Stack>

      <Stack gap={3} p={4} width={400}>
        <Button variant={'text'} color={'info'}>
          Button
        </Button>

        <Button variant={'outlined'} color={'info'}>
          Button
        </Button>

        <Button variant={'contained'} color={'info'}>
          Button
        </Button>

        <Button variant={'contained'} color={'info'} disabled>
          Button
        </Button>
      </Stack>
    </Stack>
  )
}
