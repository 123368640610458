import { useMediaQuery, Container, Stack, useTheme } from '@mui/material'
import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { ScrollRestoration } from 'react-router-dom'

import { ITermsAndPrivacyConfigValue } from '@common/types'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'

import { BlockedDialog } from '@features/blocked-dialog'
import { WelcomeDialog } from '@features/welcome-dialog'

import { AppLoader } from '@entities/app-loader'

import { WrapperForMainLayout } from '@shared/ui'

import { BlockingWindows } from './components'

interface IProps extends PropsWithChildren {
  walletsWithPageTabSwitcher?: ReactNode
  showAppLoaderManually?: boolean
  isVerified: boolean
  blockedByTermsAndPrivacy: ITermsAndPrivacyConfigValue
  blocked: boolean
}

export function WithMainLayout({
  children,
  walletsWithPageTabSwitcher,
  showAppLoaderManually,
  isVerified,
  blockedByTermsAndPrivacy,
  blocked,
}: IProps) {
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  const showContent = useMemo(
    () => !blocked && isVerified,
    [blocked, isVerified]
  )

  return (
    <WrapperForMainLayout>
      <ScrollRestoration />
      <AppBar showNotificationBar={!isVerified} blocked={blocked} />
      {showContent && walletsWithPageTabSwitcher}

      <Container
        disableGutters={!lgAndUp}
        maxWidth="xl"
        sx={{
          mt: { xs: 0, md: 3.5 },
          pb: 3,
          flex: 1,
          minHeight: 'max-content',
        }}
      >
        <Stack direction="row" gap={5} justifyContent="space">
          <Container
            maxWidth="xl"
            disableGutters
            sx={{
              transition: theme.transitions.create(['width', 'height']),
            }}
          >
            <BlockingWindows blocked={blocked} verified={isVerified} />
            {showContent && children}
          </Container>
        </Stack>
      </Container>

      <Footer />
      <AppLoader showManually={showAppLoaderManually} />
      <WelcomeDialog />
      <BlockedDialog blocked={blockedByTermsAndPrivacy.blocked} />
    </WrapperForMainLayout>
  )
}
