import { SxProps } from '@mui/material'

import { IOperationInfo } from '../../model'

import { OperationInfoItem } from './operation-info-item'
import { OperationInfoWrapper } from './styled'

interface IProps {
  operationInfo: IOperationInfo[]
  sx?: SxProps
}

export function OperationInfoList({ operationInfo, sx }: IProps) {
  return (
    <OperationInfoWrapper sx={sx}>
      {operationInfo.map((item, index) => (
        <OperationInfoItem
          keyName={item.keyName}
          value={item.value}
          key={index}
        />
      ))}
    </OperationInfoWrapper>
  )
}
