import { useState } from 'react'

import { useTransactionDetails } from '@entities/transaction'

import { useTransactionForRender } from './use-transaction-details-for-render'

export function useTransaction() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [transactionId, setTransactionId] = useState<string | null>(null)

  const {
    data: cryptoTransactionDetails,
    refetch,
    isLoading,
  } = useTransactionDetails(transactionId)

  const cryptoTransactionDetailsForRender = useTransactionForRender(
    cryptoTransactionDetails
  )

  const handleClose = () => {
    setIsOpen(false)

    setTimeout(setTransactionId, 300, null)
  }

  const handleOpen = (id: string | null) => {
    setIsOpen(true)

    setTransactionId(id)
  }

  return {
    handleClose,
    handleOpen,
    cryptoTransactionDetailsForRender,
    isLoading,
    isOpen,
    transactionId,
    isSpam: cryptoTransactionDetails?.spam,
    refetch,
  }
}
