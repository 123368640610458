import { useMemo } from 'react'

import { useAccountList } from '@entities/account'

import { useFiatMenuList, useProfileMenuList } from '../../../model'

import { AccountChooser } from './account-chooser'
import { MenuList } from './menu-list'
import { UserMenuDivider } from './styled'

interface IProps {
  onClose: () => void
}

export function UserMenuContent({ onClose }: IProps) {
  const fiatMenu = useFiatMenuList()
  const profileMenuList = useProfileMenuList()
  const { data: accountList } = useAccountList()

  const isShowAccountChooser = useMemo(
    () => accountList && !!accountList.length,
    [accountList]
  )

  return (
    <>
      {isShowAccountChooser && (
        <>
          <AccountChooser />
          <UserMenuDivider />
          <MenuList handleClose={onClose} menuList={fiatMenu} />
          <UserMenuDivider />
        </>
      )}

      <MenuList handleClose={onClose} menuList={profileMenuList} />
    </>
  )
}
