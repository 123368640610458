import {
  Box,
  CircularProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

interface IProps {
  timeLeft: number
  sx?: SxProps
}

export function QuoteValidTimer({ timeLeft, sx }: IProps) {
  const { t } = useTranslation('features')

  return (
    <Box sx={sx}>
      {timeLeft > 0 && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          display="flex-inline"
        >
          <Box
            width={8}
            height={8}
            borderRadius="100%"
            bgcolor="warning.main"
          />

          <Typography
            variant="body6"
            color="text.secondary"
            lineHeight="normal"
            pt="3px"
            pr={1}
          >
            <Trans
              i18nKey="exchange.confirmation.quote-valid"
              ns="features"
              values={{ timeLeft }}
              components={{
                span: (
                  <Box
                    component="span"
                    sx={{ display: 'inline-block', textAlign: 'end' }}
                    width={20}
                  />
                ),
              }}
            />
          </Typography>

          <Box width={24} height={24} position="relative" overflow="hidden">
            <CircularProgress
              size={24}
              color="primary"
              sx={{ position: 'absolute' }}
            />
          </Box>
        </Stack>
      )}

      {timeLeft <= 0 && (
        <Typography variant="subtitle4" color="error" textAlign="right">
          {t('exchange.confirmation.quote-expired')}
        </Typography>
      )}
    </Box>
  )
}
