import { Box, Stack, SxProps, Typography } from '@mui/material'
import React, { forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUploadFileSettings } from '@shared/settings'
import { formatBytes, formatAllowedFileTypes } from '@shared/utils'

import { ReactComponent as UploadCloud } from './assets/upload-cloud.svg'
import { StyledDragNDropAreaWrap } from './styled'

interface IProps {
  onClickUpload: () => void
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  onSelectFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
  uploadDocTitle: string
  sx?: SxProps
}

export const DragNDropArea = forwardRef(function DragNDropArea(
  {
    onClickUpload,
    onDrop,
    onDragOver,
    onSelectFiles,
    uploadDocTitle,
    sx,
  }: IProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation('shared')
  const { data: settings, refetch } = useUploadFileSettings()
  const allowedTypes = settings?.allowedTypes || []

  useEffect(() => {
    !settings && refetch()
  }, [settings])

  return (
    <StyledDragNDropAreaWrap
      variant="outlined"
      onClick={onClickUpload}
      onDrop={onDrop}
      onDragOver={onDragOver}
      sx={sx}
    >
      <input
        onChangeCapture={onSelectFiles}
        ref={ref}
        type="file"
        name="files"
        accept={allowedTypes.join(',')}
        multiple
        hidden
      />

      <Stack justifyContent={'center'} alignItems={'center'} mb={1}>
        <UploadCloud />
      </Stack>

      <Box>
        <Typography
          variant={'body10'}
          component="p"
          textAlign="center"
          mb={0.5}
        >
          {uploadDocTitle}
        </Typography>

        <Typography
          variant={'body2'}
          textAlign="center"
          color={'text.secondary'}
        >
          {t('drag-n-drop.Only formats like', {
            formats: formatAllowedFileTypes(allowedTypes),
            size: settings?.maxFileSize && formatBytes(settings.maxFileSize),
          })}
        </Typography>
      </Box>
    </StyledDragNDropAreaWrap>
  )
})
