import { Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TAccountVariant } from '@common/types'

import { WalletCardPaper } from '@entities/wallet'

import { ReactComponent as BusinessIcon } from '../../assets/business.svg'
import { ReactComponent as PersonalIcon } from '../../assets/personal.svg'

import { InReviewBadgeWrapper } from './styled'

interface IProps {
  accountType: TAccountVariant
}

type TLowerAccVariantStandardKeys = Lowercase<TAccountVariant>

export function WalletInReviewCard({ accountType }: IProps) {
  const { t } = useTranslation(['features', 'common'])

  return (
    <WalletCardPaper sx={{ px: { xs: 2, md: 3.5 } }}>
      <Stack spacing={1} direction="row" alignItems="center" height="100%">
        {accountType === 'BUSINESS' ? <BusinessIcon /> : <PersonalIcon />}

        <Typography variant="body9">
          {t(
            `features:wallet-lis.${accountType.toLowerCase() as TLowerAccVariantStandardKeys}-in-review`
          )}
        </Typography>
      </Stack>

      <InReviewBadgeWrapper>
        <Typography variant="body15">{t('common:in-review')}</Typography>
      </InReviewBadgeWrapper>
    </WalletCardPaper>
  )
}
