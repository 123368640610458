import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { queryKeys } from '@shared/constants'
import { TIsApproved, useAccountStore } from '@shared/store'

import { fetchAccountList } from '../../api'

export const useAccountList = () => {
  const activeAccount = useAccountStore((state) => state.account)

  const setActiveAccount = useAccountStore((state) => state.setAccount)
  const setIsApproved = useAccountStore((state) => state.setIsApproved)

  const query = useQuery({
    queryKey: [queryKeys.accounts],
    queryFn: fetchAccountList,
  })

  const { data: accountList } = query

  useEffect(() => {
    if (!accountList || accountList.length === 0) {
      return
    }

    setIsApproved(
      accountList.reduce((acc, account) => {
        acc[account.id] = account.status === 'APPROVED'

        return acc
      }, {} as TIsApproved)
    )

    if (!activeAccount) {
      setActiveAccount(accountList.length ? accountList[0] : null)

      return
    }

    const found = accountList.find(
      (account) => account.id === activeAccount?.id
    )

    if (!found) {
      setActiveAccount(accountList.length ? accountList[0] : null)

      return
    }

    setActiveAccount(found)
  }, [accountList, activeAccount])

  return query
}
