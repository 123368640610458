import { Drawer, styled } from '@mui/material'

import { mobileDrawerPt, mobileDrawerPx, scrollbarSx } from '@shared/ui'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '600px',
    maxHeight: 'calc(100% - 40px)',
    padding: `${mobileDrawerPt} ${mobileDrawerPx} 72px`,
    marginLeft: 'auto',
    marginRight: 'auto',

    borderRadius: '16px 16px 0 0',

    ...scrollbarSx,
  },
  zIndex: theme.zIndex.drawer + 2,
}))
