import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'

import { fetchTransactionsDetails } from '../../api'

export function useTransactionDetails(transactionId: string | null) {
  return useQuery({
    queryKey: [queryKeys.transactionDetails, transactionId],
    queryFn: () => {
      if (!transactionId) {
        return
      }

      return fetchTransactionsDetails(transactionId)
    },
    enabled: !!transactionId,
  })
}
