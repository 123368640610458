import { SxProps, Typography } from '@mui/material'
import { memo } from 'react'

import { useAccountStore } from '@shared/store'

interface IProps {
  sx?: SxProps
}

export const AccountName = memo(function AccountName({ sx }: IProps) {
  const account = useAccountStore((state) => state.account)

  const accountName =
    account?.businessApplication?.name ||
    `${account?.personalApplication?.firstName} ${account?.personalApplication?.lastName}`

  return (
    <Typography variant="title1" sx={sx}>
      {accountName}
    </Typography>
  )
})
