import { useState } from 'react'

import { useExchangeDetails, useExchangeForRender } from '@entities/exchange'

export function useExchange() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [exchangeId, setExchangeId] = useState<string | null>(null)

  const { data: exchangeDetails, isLoading } = useExchangeDetails(exchangeId)

  const exchangeDetailsForRender = useExchangeForRender(exchangeDetails)

  const handleClose = () => {
    setIsOpen(false)

    setTimeout(setExchangeId, 300, null)
  }

  const handleOpen = (id: string | null) => {
    setIsOpen(true)

    setExchangeId(id)
  }

  return {
    handleClose,
    handleOpen,
    exchangeDetailsForRender,
    isLoading,
    isOpen,
    exchangeId,
  }
}
