import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { PageTabSwitcher } from '@widgets/page-tab-switcher'
import { Wallets } from '@widgets/wallets'

import {
  NoWalletsMessage,
  PaperRounded,
  WrapperForOutletWithWallets,
} from '@shared/ui'

import { useMainLayout } from './hooks'
import { WithMainLayout } from './with-main-layout'

type TPaddingVariant = 'form' | 'list'

interface IProps {
  paddingVariant?: TPaddingVariant
}

export function MainWalletLayout({ paddingVariant = 'form' }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const { isVerified, isWalletList, blockedByTermsAndPrivacy, isBlocked } =
    useMainLayout()

  const pageTabSwitcherStyles: SxProps = {
    pb: { xs: 0, md: 3 },
  }

  return (
    <WithMainLayout
      blockedByTermsAndPrivacy={blockedByTermsAndPrivacy}
      isVerified={isVerified}
      blocked={isBlocked}
      walletsWithPageTabSwitcher={
        !mdAndUp && (
          <>
            <Wallets sx={{ mb: 2 }} />
            <PageTabSwitcher template="wallet" sx={pageTabSwitcherStyles} />
          </>
        )
      }
    >
      <PaperRounded sx={{ px: { xs: 2, md: 6 } }}>
        {mdAndUp && (
          <>
            <Wallets sx={{ mb: 5 }} />
            <PageTabSwitcher template="wallet" sx={pageTabSwitcherStyles} />
          </>
        )}

        <WrapperForOutletWithWallets
          variant={isWalletList ? paddingVariant : 'form'}
        >
          {isWalletList ? <Outlet /> : <NoWalletsMessage />}
        </WrapperForOutletWithWallets>
      </PaperRounded>
    </WithMainLayout>
  )
}
