import { useMediaQuery, Typography, useTheme } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomModal } from '@shared/ui'

import { WithdrawalSuccessTitle } from './withdrawal-success-title'

interface IProps {
  onClose: () => void
  open: boolean
  withdrawId?: string
}

export const SuccessWithdrawalModal = memo(function SuccessWithdrawModal({
  onClose,
  open,
  withdrawId,
}: IProps) {
  const { t } = useTranslation(['features', 'shared'])
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <CustomModal onClose={onClose} open={open}>
      <WithdrawalSuccessTitle sx={{ mb: 2 }} />

      <Typography variant={mdAndUp ? 'body2' : 'body4'} mb={2}>
        {t('withdraw.success-content')}
      </Typography>

      <Typography variant="subtitle4" color="text.secondary">
        {t('withdraw.success-after-content', {
          id: withdrawId,
        })}
      </Typography>
    </CustomModal>
  )
})
