import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IWallet } from '@common/types'

interface IWalletState {
  wallet?: IWallet
  setWallet: (wallet: IWallet) => void

  walletsSliderHeight: number
  setWalletsSliderHeight: (payload: number) => void
}

export const useWalletStore = create<IWalletState>()(
  devtools(
    (set) => ({
      setWallet: (wallet) => set({ wallet }),

      walletsSliderHeight: 0,
      setWalletsSliderHeight: (walletsSliderHeight) =>
        set({ walletsSliderHeight }),
    }),
    {
      name: 'walletStore',
    }
  )
)
