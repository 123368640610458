// import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { useMediaQuery, useTheme, /*  Box, */ Stack } from '@mui/material'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import '@splidejs/react-splide/css'

import { useAccountList } from '@entities/account'
import { useWalletList, WalletCard } from '@entities/wallet'

import { useAccountStore } from '@shared/store'
import { AnimationViewer, SliderWrapper } from '@shared/ui'
import { tabIndexKeyUpCallback } from '@shared/utils'

import { CreateWalletButton } from '../create-wallet-button'

import { WalletInReviewCard } from './components'
import { CustomSplideWrapper /* , SlideButton */ } from './styled'

export const WalletsList = forwardRef<HTMLDivElement>(
  function WalletsList(_, ref) {
    const { data: walletList, isLoading: walletsLoading } = useWalletList()
    const { data: accountList, isLoading: accountsLoading } = useAccountList()
    const theme = useTheme()
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
    const splideRef = useRef<Splide>(null)
    const activeAccount = useAccountStore((s) => s.account)
    const [activeIndex, setActiveIndex] = useState(0)

    const onClickSlide = (index?: number) => {
      splideRef.current?.splide?.go(index || 0)

      setActiveIndex(index || 0)
    }

    useEffect(() => {
      if (walletList && walletList?.length) {
        onClickSlide()
      }
    }, [walletList])

    const applicationOnReview = useMemo(
      () =>
        accountList?.find(
          (account) =>
            account.status === 'NEW' && account.id === activeAccount?.id
        ),
      [accountList, activeAccount]
    )

    const isLoading = useMemo(() => {
      if (!accountList?.length && !accountsLoading) {
        return false
      }

      return applicationOnReview
        ? accountsLoading
        : walletsLoading || accountsLoading
    }, [walletsLoading, accountsLoading, applicationOnReview, accountList])

    return (
      <CustomSplideWrapper ref={ref}>
        {!isLoading &&
          (walletList || applicationOnReview || !accountList?.length) && (
            <SliderWrapper>
              <Splide
                onMove={(e) => {
                  !mdAndUp &&
                    setActiveIndex(e?.Components.Controller.getIndex())
                }}
                hasTrack={false}
                aria-label="Partners"
                className="splide"
                ref={splideRef}
                options={{
                  arrows: false,
                  focus: 0,
                  perPage: 1,
                  perMove: 1,
                  autoWidth: true,
                  pagination: !mdAndUp,
                  drag: true,
                  trimSpace: 'move',
                  gap: '24px',
                  padding: 0,
                }}
              >
                <SplideTrack>
                  {walletList?.map((item, index) => (
                    <SplideSlide
                      className={'click-slide'}
                      tabIndex={0}
                      key={item.id}
                      onClick={() => onClickSlide(index)}
                      onKeyUp={tabIndexKeyUpCallback(() => onClickSlide(index))}
                    >
                      <WalletCard
                        wallet={item}
                        active={activeIndex === index}
                      />
                    </SplideSlide>
                  ))}

                  {activeAccount && applicationOnReview && (
                    <SplideSlide key={applicationOnReview.id}>
                      <WalletInReviewCard
                        accountType={applicationOnReview.variant}
                      />
                    </SplideSlide>
                  )}

                  {accountList && accountList.length < 2 && (
                    <SplideSlide>
                      <CreateWalletButton />
                    </SplideSlide>
                  )}
                </SplideTrack>

                {/* TODO: maybe will be needed on the future */}
                {/* <Box sx={{ height: 0 }} className="splide__arrows">
                <SlideButton isBack={true} className="splide__arrow--prev">
                  <ArrowForwardIosRoundedIcon />
                </SlideButton>

                <SlideButton className="splide__arrow--next">
                <ArrowForwardIosRoundedIcon />
                </SlideButton>
                </Box> */}
              </Splide>
            </SliderWrapper>
          )}

        {isLoading && (
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: { xs: 'center', md: 'start' },
            }}
          >
            <AnimationViewer
              style={{ width: '92px', height: '92px' }}
              animation="searchAnimations"
            />
          </Stack>
        )}
      </CustomSplideWrapper>
    )
  }
)
