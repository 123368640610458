import { SxProps } from '@mui/material'

export const createWalletModalSx: SxProps = {
  '& .MuiDialog-paper': {
    minWidth: '600px !important',
    overflow: 'visible',
  },
  '& .MuiDialogContent-root': {
    overflow: 'visible',
    padding: '40px',
  },
}
