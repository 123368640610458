import { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useAppLoaderStore } from '@entities/app-loader'

import {
  businessApplicationMainPath,
  personalApplicationMainPath,
} from '@shared/constants'
import { useUploadFileSettings } from '@shared/settings'

import { useMainLayout } from './hooks'
import { WithMainLayout } from './with-main-layout'

export function MainGeneralLayout() {
  const setShowLoader = useAppLoaderStore((state) => state.setShow)

  const location = useLocation()

  const {
    data: uploadFileSettings,
    isLoading,
    refetch,
  } = useUploadFileSettings()

  const { isBlocked, userDetailLoaded, blockedByTermsAndPrivacy, isVerified } =
    useMainLayout()

  const isApplications = useMemo(
    () =>
      location.pathname.includes(businessApplicationMainPath) ||
      location.pathname.includes(personalApplicationMainPath),
    [location.pathname]
  )

  const takeOffLoader = async () => {
    if (!uploadFileSettings && isApplications) {
      await refetch()
    }

    if (userDetailLoaded && (isApplications ? uploadFileSettings : true)) {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    takeOffLoader()
  }, [userDetailLoaded, uploadFileSettings, location.pathname])

  return (
    <WithMainLayout
      blockedByTermsAndPrivacy={blockedByTermsAndPrivacy}
      isVerified={isVerified}
      blocked={isBlocked}
      showAppLoaderManually={isApplications && isLoading}
    >
      {(isApplications ? uploadFileSettings : true) && <Outlet />}
    </WithMainLayout>
  )
}
