import { useAppLoaderStore } from '../model'

import { RocketInSpace } from './components'
import { Wrapper, AnimationWrapper } from './styled'

interface IProps {
  showManually?: boolean
}

export function AppLoader({ showManually }: IProps) {
  const { show } = useAppLoaderStore()

  if (!showManually && !show) {
    return null
  }

  return (
    <Wrapper>
      <AnimationWrapper>
        <RocketInSpace />
      </AnimationWrapper>
    </Wrapper>
  )
}
