import { LoadingButton } from '@mui/lab'
import { Stack, Button } from '@mui/material'
import { memo, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TWithdrawalForm } from '@entities/withdrawal'

import { TConfirmationOptions } from '@shared/types'
import { IOperationInfo, CustomModal, OperationInfoList } from '@shared/ui'

import { useCreateWithdrawal } from '../../model'

import { WithdrawalConfirmationAttentionMessage } from './withdrawal-confirmation-attention-message'
import { WithdrawalConfirmationTitle } from './withdrawal-confirmation-title'

interface IProps {
  onClose: () => void
  open: boolean
  setWithdrawConfirmationOptions: (payload: TConfirmationOptions) => void
  setAttemptsCounter: (value: number) => void
  setIsShowOtp: (value: boolean) => void
}

export const WithdrawalDetailsModal = memo(function WithdrawDetailsModal({
  onClose,
  open,
  setWithdrawConfirmationOptions,
  setAttemptsCounter,
  setIsShowOtp,
}: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation(['features', 'shared'])

  const { getValues } = useFormContext<TWithdrawalForm>()

  const handleCreateWithdrawal = useCreateWithdrawal({
    onClose,
    setAttemptsCounter,
    setIsLoading,
    setIsShowOtp,
    setWithdrawalConfirmationOptions: setWithdrawConfirmationOptions,
  })

  const operationInfo: IOperationInfo[] = useMemo(
    () => [
      {
        keyName: t('withdraw.amount'),
        value: getValues('amount'),
      },
      {
        keyName: t('withdraw.iban'),
        value: getValues('iban'),
      },
    ],
    [open]
  )

  const buttonText = useMemo(
    () =>
      `${t('withdraw.withdraw', { value: `${getValues('amount') || 0} EUR` })}`,
    [open]
  )

  return (
    <CustomModal onClose={onClose} open={open} loading={isLoading}>
      <WithdrawalConfirmationTitle sx={{ mb: 4 }} />
      <OperationInfoList operationInfo={operationInfo} sx={{ mb: 1.5 }} />
      <WithdrawalConfirmationAttentionMessage sx={{ mb: 3 }} />

      <Stack spacing={2} direction="row">
        <LoadingButton
          variant="contained"
          size="large"
          onClick={handleCreateWithdrawal}
          loading={isLoading}
          sx={{ flexBasis: '50%' }}
        >
          {buttonText}
        </LoadingButton>

        <Button
          variant="outlined"
          size="large"
          onClick={onClose}
          disabled={isLoading}
          sx={{ flexBasis: '50%' }}
        >
          {t('shared:button.cancel')}
        </Button>
      </Stack>
    </CustomModal>
  )
})
