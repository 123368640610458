import { SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  sx?: SxProps
}

export function WithdrawalConfirmationAttentionMessage({ sx }: IProps) {
  const { t } = useTranslation('features')

  return (
    <Typography variant="body6" sx={sx}>
      {t('withdraw.account-name-must-match')}
    </Typography>
  )
}
