import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const DepositInfoRoute: RouteObject = {
  path: routeNamesPaths.deposit,
  lazy: async () => {
    const { DepositPage } = await import('./ui')

    return { Component: DepositPage }
  },
}
