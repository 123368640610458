export const idvStatuses = ['NEW', 'PENDING', 'APPROVED', 'REJECTED'] as const
export type TIdvStatus = (typeof idvStatuses)[number]

export enum EUserConfigKeys {
  STATUSES = 'STATUSES',
  TERMS_AND_PRIVACY = 'TERMS_AND_PRIVACY',
}
export const userConfigKeys = Object.keys(EUserConfigKeys) as TUserConfigKey[]
export type TUserConfigKey = keyof typeof EUserConfigKeys

export interface IStatusesConfigValue {
  common: {
    blocked: boolean
    blockedReason: string
  }
}

export interface ITermsAndPrivacyConfigValue {
  blocked: boolean
}

export interface IUserStatusesConfig {
  key: EUserConfigKeys.STATUSES
  value: IStatusesConfigValue
}

export interface IUserTermsAndPrivacyConfig {
  key: EUserConfigKeys.TERMS_AND_PRIVACY
  value: ITermsAndPrivacyConfigValue
}

export type TUserConfig = IUserStatusesConfig | IUserTermsAndPrivacyConfig

export interface IUser {
  id: string
  phoneNumber: string
  email: string
  createdAt: string
  updatedAt: string
  fullName: string | null
  keycloakId: string
  idvStatus: TIdvStatus
  configs: TUserConfig[]
}

export interface IIdvInfo {
  firstName: string
  lastName: string
  nationality: string
  birthDate: string
  documentNumber: string
  documentType: string
  documentExpire: string
  documentIssue: string
  citizenship: string
  issuingCountry: string
}
