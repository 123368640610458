import { Typography } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@shared/auth'
import { useAccountStore } from '@shared/store'

import { StyledUserProfileButton } from './styled'
import { UserAvatar } from './user-avatar'

interface IAccountProfileButtonProps {
  id: string
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export function AccountProfileButton({
  id,
  onClick,
}: IAccountProfileButtonProps) {
  const { t } = useTranslation('features')
  const currentAccount = useAccountStore((state) => state.account)

  const {
    store: { user },
  } = useAuth()

  return (
    <StyledUserProfileButton
      id={id}
      onClick={onClick}
      show={!!currentAccount}
      disableFocusRipple
    >
      {currentAccount && (
        <Typography variant="body3" flex={1} ml={1} color="primary.dark">
          {t(
            `app-bar.${user?.idvStatus === 'APPROVED' ? currentAccount.variant : 'unverified'}`
          )}
        </Typography>
      )}

      <UserAvatar />
    </StyledUserProfileButton>
  )
}
