import { memo, useCallback, useState } from 'react'

import { TConfirmationOptions } from '@shared/types'

import {
  SuccessWithdrawalModal,
  WithdrawalConfirmationOtp,
  WithdrawalDetailsModal,
} from './components'

interface IWithdrawalConfirmation {
  openWithdrawDetails: boolean
  onCloseWithdrawDetails: () => void
}

export const WithdrawalConfirmationModals = memo(
  function WithdrawConfirmationModals({
    openWithdrawDetails,
    onCloseWithdrawDetails,
  }: IWithdrawalConfirmation) {
    const [isShowOtp, setIsShowOtp] = useState<boolean>(false)
    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [attemptsCounter, setAttemptsCounter] = useState<number>(0)

    const [withdrawalConfirmationOptions, setWithdrawalConfirmationOptions] =
      useState<TConfirmationOptions>(null)

    const handleCloseSuccessModal = useCallback(() => {
      setOpenSuccess(false)

      setWithdrawalConfirmationOptions(null)
    }, [])

    return (
      <>
        <WithdrawalDetailsModal
          onClose={onCloseWithdrawDetails}
          open={openWithdrawDetails}
          setWithdrawConfirmationOptions={setWithdrawalConfirmationOptions}
          setAttemptsCounter={setAttemptsCounter}
          setIsShowOtp={setIsShowOtp}
        />

        <WithdrawalConfirmationOtp
          withdrawConfirmationOptions={withdrawalConfirmationOptions}
          setIsShowOtp={setIsShowOtp}
          setOpenSuccess={setOpenSuccess}
          setAttemptsCounter={setAttemptsCounter}
          setWithdrawConfirmationOptions={setWithdrawalConfirmationOptions}
          isShowOtp={isShowOtp}
          attemptsCounter={attemptsCounter}
        />

        <SuccessWithdrawalModal
          onClose={handleCloseSuccessModal}
          open={openSuccess}
          withdrawId={withdrawalConfirmationOptions?.id}
        />
      </>
    )
  }
)
