import { styled } from '@mui/material'

import { blue } from '@shared/constants'

interface IProps {
  open?: boolean
  error?: boolean
}

export const SelectedItemBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'error',
})<IProps>(({ theme, open, error }) => ({
  height: 52,
  overflow: 'hidden',
  border: `1px solid ${blue[950]}`,
  borderRadius: '0 10px 10px 0',
  boxSizing: 'border-box',
  minWidth: 180,
  borderLeftWidth: '0px !important',

  ...(error && {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  }),

  '&.adornment': {
    borderColor: 'transparent',
  },

  [theme.breakpoints.up('md')]: {
    minWidth: 250,
    zIndex: 1,
    position: 'relative',

    ...(open && {
      boxShadow: theme.shadows[3],
      borderRadius: '0 10px 0 0',
    }),
  },
  [theme.breakpoints.down('md')]: {
    borderLeft: 0,
  },
}))
