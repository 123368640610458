import { Box, Stack, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'

import { grey } from '@shared/constants'

import { StyledErrorTechnicalContainer } from './styled'

export function LayoutForErrorTechnicalPages({ children }: PropsWithChildren) {
  const theme = useTheme()

  return (
    <Stack
      minHeight="100vh"
      bgcolor={{ xs: theme.palette.common.white, md: grey[50] }}
    >
      <AppBar blocked={false} />

      <StyledErrorTechnicalContainer maxWidth="xl">
        <Box px={{ md: 5 }}>{children}</Box>
      </StyledErrorTechnicalContainer>

      <Footer />
    </Stack>
  )
}
