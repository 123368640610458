import { styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  open?: boolean
  isItem?: boolean
}

export const AccountItemBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isItem',
})<IProps>(({ theme, open, isItem }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',
  height: '100%',
  cursor: 'pointer',
  transition: theme.transitions.create(['background', 'borderRadius']),

  [theme.breakpoints.up('md')]: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  ...(open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),

  ...(isItem && {
    background: theme.palette.common.white,
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',

    '&:hover': {
      background: grey[100],
    },
  }),
}))
