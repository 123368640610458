import { LoadingButton } from '@mui/lab'
import {
  useMediaQuery,
  Typography,
  Stack,
  Button,
  useTheme,
} from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { grey } from '@shared/constants'
import { IOperationInfo, CustomModal, OperationInfoList } from '@shared/ui'

import { QuoteValidTimer } from './quote-valid-timer'

interface IProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  disabled: boolean
  timeLeft: number
  exchangeInfo: IOperationInfo[]
}

export const ExchangeConfirmationModal = memo(
  function ExchangeConfirmationModal({
    loading,
    onClose,
    onConfirm,
    open,
    timeLeft,
    exchangeInfo,
    disabled,
  }: IProps) {
    const theme = useTheme()
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
    const { t } = useTranslation(['features', 'shared'])

    return (
      <CustomModal open={open} onClose={onClose} loading={loading}>
        <Typography variant={mdAndUp ? 'subtitle1' : 'subtitle3'} mb={2}>
          {t('exchange.confirmation.title')}
        </Typography>

        <QuoteValidTimer timeLeft={timeLeft} sx={{ mb: 2 }} />

        <OperationInfoList
          operationInfo={exchangeInfo}
          sx={{
            mb: 2,
            background: { xs: 'none', md: grey[50] },
            border: {
              xs: 'none',
              md: `1px solid ${theme.palette.primary.dark}`,
            },
          }}
        />

        <Stack direction="row" spacing={2}>
          <Button
            type="button"
            variant="outlined"
            disabled={loading || disabled}
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t('shared:button.cancel')}
          </Button>

          <LoadingButton
            type="button"
            variant="contained"
            loading={loading}
            disabled={disabled}
            size="large"
            fullWidth
            onClick={onConfirm}
          >
            {t('shared:button.confirm')}
          </LoadingButton>
        </Stack>
      </CustomModal>
    )
  }
)
