import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const SuccessMessageWrapper = styled('div')(({ theme }) => ({
  padding: '28px 24px',
  marginBottom: '16px',

  backgroundColor: grey[50],
  border: '1px solid',
  borderColor: theme.palette.primary.dark,
  borderRadius: '16px',
}))
