import { useQuery } from '@tanstack/react-query'
import Decimal from 'decimal.js'

import { TCurrency } from '@common/types'

import { fetchFeePlans } from '@entities/fee-plan/api'

import { queryKeys } from '@shared/constants'

export function useFeePlans(accountId?: string) {
  const query = useQuery({
    queryKey: [queryKeys.feePlans],
    queryFn: () => fetchFeePlans(accountId),
    refetchInterval: 10 * 60 * 1000,
    enabled: !!accountId,
  })

  const feePlans = query.data

  const calculateFee = (amount?: string | number, currency?: TCurrency) => {
    if (!amount) {
      return '0.00'
    }

    const amountNumber = Number(amount)
    const currentFeePlan = feePlans?.find((plan) => plan.currency === currency)

    if (!amountNumber || !currentFeePlan) {
      return '0.00'
    }

    const feeRule = currentFeePlan.scales.find((rule) => {
      return amountNumber >= rule.min && amountNumber <= rule.max
    })

    if (!feeRule) {
      return '0.00'
    }

    const dAmount = new Decimal(amount)

    const result = dAmount.mul(feeRule.percents).div(100)

    if (result.lessThan(currentFeePlan.minFee)) {
      return currentFeePlan.minFee.toFixed(2)
    }

    return result.toNumber().toFixed(2)
  }

  const getMinAmount = (currency: TCurrency) => {
    const currentFeePlan = feePlans?.find((plan) => plan.currency === currency)

    if (!currentFeePlan) {
      return
    }

    const minAmountArray = currentFeePlan.scales.map((item) => item.min)

    return Math.min(...minAmountArray)
  }

  return { ...query, calculateFee, getMinAmount }
}
