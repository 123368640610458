import { useKeycloak } from '@react-keycloak/web'

import { fetchUserIdvData, fetchUserInfo } from '../api'
import { useAuthStore } from '../store'

export function useAuth() {
  const authStore = useAuthStore()
  const { keycloak } = useKeycloak()

  const getUserFromApi = async () => {
    authStore.setInProcess(true)

    // TODO: need to add to the tanstack query
    const response = await fetchUserInfo()

    if (response) {
      authStore.setUser(response)
    }

    authStore.setInProcess(false)

    return response
  }

  const getUserIdvDataFromApi = async () => {
    try {
      authStore.setIsUserDetailsLoading(true)

      const response = await fetchUserIdvData()

      if (response) {
        authStore.setUserIdvData(response)
      }

      return response
    } finally {
      authStore.setIsUserDetailsLoading(false)
    }
  }

  const changePassword = async () => {
    await keycloak.login({ action: 'UPDATE_PASSWORD' })
  }

  const logout = async () => {
    await keycloak.logout()
  }

  return {
    store: authStore,
    fetchUser: getUserFromApi,
    changePassword,
    fetchUserDetailInfo: getUserIdvDataFromApi,
    logout,
  }
}
