import { SxProps, Theme } from '@mui/material'

import { mobileDrawerPx, mobileDrawerPt, mobileTitlePb } from '@shared/ui'

export const warningTitleSx: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.down('md')]: {
    width: `calc(100% + ${mobileDrawerPx} * 2)`,
    marginLeft: `-${mobileDrawerPx}`,
    marginTop: `-${mobileDrawerPt}`,
    marginBottom: '16px',
    padding: `${mobileDrawerPt} ${mobileDrawerPx} ${mobileTitlePb}`,
    backgroundColor: theme.palette.error.light,
  },
})
