import { SxProps, Theme } from '@mui/material'
import { ReactNode, useRef } from 'react'

import { StyledBox } from './styled'

interface IProps {
  children: ReactNode
  sx?: SxProps<Theme>
}

export function SliderWrapper({ children, sx }: IProps) {
  const ref = useRef<HTMLDivElement>(null)

  const handleMouseDown = () => {
    ref.current?.classList.add('pressed')
  }

  const handleMouseUp = () => {
    ref.current?.classList.remove('pressed')
  }

  return (
    <StyledBox
      ref={ref}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      sx={sx}
    >
      {children}
    </StyledBox>
  )
}
