import { Button, styled } from '@mui/material'
import { ElementType } from 'react'

interface IProps {
  component: ElementType
}

export const ChooseAccountButton = styled(Button)<IProps>(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  borderColor: theme.palette.primary.dark,
  borderRadius: 0,

  '&:hover': {
    backgroundColor: theme.palette.primary.light + '!important',
    borderColor: theme.palette.primary.dark,
  },

  '&:not(:last-of-type)': {
    borderBottomWidth: 0,
  },

  '&.MuiButtonBase-root.MuiButton-root:first-of-type': {
    borderTopLeftRadius: theme.spacing(1.5),
    borderTopRightRadius: theme.spacing(1.5),
  },

  '&.MuiButtonBase-root.MuiButton-root:last-of-type': {
    borderBottomLeftRadius: theme.spacing(1.5),
    borderBottomRightRadius: theme.spacing(1.5),
  },

  '&.MuiButtonBase-root': {
    padding: '12px 16px',
  },

  [theme.breakpoints.up('sm')]: {
    '&.MuiButtonBase-root.MuiButton-root:first-of-type': {
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
    },

    '&.MuiButtonBase-root.MuiButton-root:last-of-type': {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
}))
