import {
  ICreateTransactionResponse,
  ITransactionDetailsPathParams,
} from '@common/types'

import { api } from '@shared/http-instance'

export function transactionSendOtp({
  transactionId,
}: ITransactionDetailsPathParams) {
  return api
    .put<ICreateTransactionResponse>(`/transactions/${transactionId}/send-otp`)
    .then((res) => res.data)
}
