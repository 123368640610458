import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material'

import { CopyButton } from '@shared/ui'

interface IProps {
  title: string
  warningMessage?: string
  warning?: boolean
  id?: string
  sx?: SxProps<Theme>
  copyButton?: boolean
}

export function ModalTitleWithWarning({
  title,
  id,
  copyButton,
  warningMessage,
  warning,
  sx,
}: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const helperTextColor = warning ? 'error.main' : 'text.secondary'

  return (
    <Box sx={sx}>
      <Typography
        variant={mdAndUp ? 'subtitle1' : 'subtitle3'}
        color={warning ? 'error.main' : undefined}
      >
        {title}
      </Typography>

      {id && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body3" color={helperTextColor}>
            {`ID: ${id}`}
          </Typography>

          {copyButton && (
            <CopyButton
              copyableElementName="ID"
              value={id}
              sx={{ color: helperTextColor }}
            />
          )}
        </Stack>
      )}

      {warningMessage && (
        <Typography variant="body3" color={helperTextColor}>
          {warningMessage}
        </Typography>
      )}
    </Box>
  )
}
