import to from 'await-to-js'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useFiatBalances } from '@entities/fiat-balance'
import { TWithdrawalForm, createWithdrawal } from '@entities/withdrawal'

import { useAccountStore } from '@shared/store'
import { TConfirmationOptions } from '@shared/types'
import { fileToBase64, getTimeToLeftFromISODate } from '@shared/utils'

interface IProps {
  setAttemptsCounter: (value: number) => void
  setWithdrawalConfirmationOptions: (payload: TConfirmationOptions) => void
  setIsLoading: (value: boolean) => void
  onClose: () => void
  setIsShowOtp: (value: boolean) => void
}

export function useCreateWithdrawal({
  onClose,
  setAttemptsCounter,
  setIsLoading,
  setIsShowOtp,
  setWithdrawalConfirmationOptions,
}: IProps) {
  const selectedAccount = useAccountStore((s) => s.account)
  const { data: fiatBalances } = useFiatBalances(selectedAccount?.id)

  const { t } = useTranslation('shared')

  const { getValues } = useFormContext<TWithdrawalForm>()

  const handleCreateWithdrawal = async () => {
    setAttemptsCounter(0)

    setWithdrawalConfirmationOptions(null)

    if (!selectedAccount) {
      toast.error('Please, select account')

      return
    }

    if (!fiatBalances) {
      toast.error('Please, wait a bit longer')

      return
    }

    setIsLoading(true)

    let base64FileList: string[] | undefined

    const fileList = getValues('fileList')

    if (fileList?.length) {
      const [errorBase64, response] = await to(
        Promise.all(fileList.map(async (file) => await fileToBase64(file)))
      )

      if (errorBase64) {
        setIsLoading(false)

        toast.error(t('errors.oops'))

        return
      }

      base64FileList = response
    }

    const [error, response] = await to(
      createWithdrawal({
        balanceId: fiatBalances[0].id,
        amount: Number(getValues('amount')),
        iban: getValues('iban'),
        fileList: base64FileList,
      })
    )

    setIsLoading(false)

    const isFail = error || !response

    if (isFail) {
      toast.error(t('errors.oops'))

      return
    }

    setWithdrawalConfirmationOptions({
      id: response.withdrawalId,
      end: getTimeToLeftFromISODate(response.confirmation.end),
      attempts: response.confirmation.tries,
    })

    onClose()

    setTimeout(setIsShowOtp, 300, true)
  }

  return handleCreateWithdrawal
}
