import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const AttentionMessageWrapper = styled('div')(({ theme }) => ({
  padding: '20px',

  border: '1px solid',
  borderColor: grey[100],
  borderRadius: '10px',

  [theme.breakpoints.up('md')]: {
    padding: '32px',
  },
}))
