import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useTheme } from '@mui/material'

import { StyledIconButton } from './styled'

interface IProps {
  onClose: () => void
  disabled?: boolean
  error?: boolean
}

export const CloseBtn = ({ onClose, disabled, error }: IProps) => {
  const theme = useTheme()

  return (
    <StyledIconButton
      className="close-btn"
      disabled={disabled}
      onClick={onClose}
      sx={{
        ...(error && {
          backgroundColor: theme.palette.error.main,
        }),
      }}
    >
      <CloseRoundedIcon
        fontSize="large"
        sx={{
          color: error ? theme.palette.error.main : theme.palette.common.black,
        }}
      />
    </StyledIconButton>
  )
}
