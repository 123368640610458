import { styled } from '@mui/material'

export const StyledFormWrapper = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '24px',
  height: '100%',
  justifyContent: 'flex-start',
}))
