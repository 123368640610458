import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'
import { useAccountStore } from '@shared/store'

import { fetchFiatBalances } from '../../api'

export function useFiatBalances(accountId?: string) {
  const isApproved = useAccountStore((state) => state.isApproved)

  return useQuery({
    queryKey: [queryKeys.fiatBalances, accountId, isApproved],
    queryFn: () => fetchFiatBalances(accountId),
    enabled: !!accountId && isApproved[accountId],
  })
}
