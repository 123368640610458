import { IFeePlan } from '@common/types'

import { api } from '@shared/http-instance'

export function fetchFeePlans(accountId?: string) {
  if (!accountId) {
    return null
  }

  return api
    .get<IFeePlan[]>(`/accounts/${accountId}/fee-plans`)
    .then((res) => res.data)
}
