import { Button, styled } from '@mui/material'

interface IProps {
  show: boolean
}

export const StyledUserProfileButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'show',
})<IProps>(({ theme, show }) => ({
  minWidth: 124,
  border: '1px solid',
  borderColor: theme.palette.primary.dark,
  '&:hover': {
    borderColor: theme.palette.primary.dark,
  },
  ...(show && {
    padding: '4px 42px 4px 8px !important',
    backgroundColor: 'white',
    borderRadius: '6px',
  }),
  ...(!show && {
    minWidth: 'initial',
    padding: 0 + '!important',
  }),
  ...theme.typography.body3,
}))
