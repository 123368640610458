import { Outlet } from 'react-router-dom'

import { PageTabSwitcher } from '@widgets/page-tab-switcher'

import { ToCryptoButton } from '@features/to-crypto-button'

import { AccountHeaderData } from '@entities/account'

import {
  NoWalletsMessage,
  PaperRounded,
  WrapperForOutletWithWallets,
} from '@shared/ui'

import { useMainLayout } from './hooks'
import { WithMainLayout } from './with-main-layout'

type TPaddingVariant = 'form' | 'list'

interface IProps {
  paddingVariant?: TPaddingVariant
}

export function MainAccountLayout({ paddingVariant = 'form' }: IProps) {
  const { isVerified, isWalletList, blockedByTermsAndPrivacy, isBlocked } =
    useMainLayout()

  return (
    <WithMainLayout
      isVerified={isVerified}
      blockedByTermsAndPrivacy={blockedByTermsAndPrivacy}
      blocked={isBlocked}
    >
      <PaperRounded sx={{ px: { xs: 2, md: 6 } }}>
        <AccountHeaderData
          toCryptoButton={<ToCryptoButton />}
          sx={{ mb: { xs: 0, md: 2 }, p: { xs: 2, md: 0 } }}
        />

        <PageTabSwitcher template={'account'} sx={{ pb: { xs: 2.5, md: 3 } }} />

        <WrapperForOutletWithWallets
          variant={isWalletList ? paddingVariant : 'form'}
        >
          {isWalletList ? <Outlet /> : <NoWalletsMessage />}
        </WrapperForOutletWithWallets>
      </PaperRounded>
    </WithMainLayout>
  )
}
