import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { TAccountVariant } from '@common/types'

import { useAccountList } from '@entities/account'
import { useCreateWalletStore } from '@entities/create-wallet'
import { useWalletList } from '@entities/wallet'

import { routeNamesPaths } from '@shared/constants'
import { useUploadFileSettings } from '@shared/settings'

interface IProps {
  accountType: TAccountVariant | null
  setIsLoading: (value: boolean) => void
}

export function useCreateWallet({ accountType, setIsLoading }: IProps) {
  const navigate = useNavigate()
  const { data: accountList } = useAccountList()
  const { refetch: updateWalletList } = useWalletList()
  const { data: uploadFileSettings, refetch } = useUploadFileSettings()

  const { setShow } = useCreateWalletStore()

  const virtualAccountByType = useMemo(
    () => accountList?.find((account) => account.variant === accountType),
    [accountList, accountType]
  )

  const getVirtualAccByType = () => {
    if (!virtualAccountByType) {
      accountType === 'PERSONAL'
        ? navigate(routeNamesPaths.paPersonalDetail)
        : navigate(routeNamesPaths.businessApplication)

      setShow(false)

      return
    }
  }

  const onCreateWallet = async () => {
    setIsLoading(true)

    if (!uploadFileSettings) {
      await refetch()
    }

    if (accountList && accountList.length > 0) {
      getVirtualAccByType()

      await updateWalletList()

      return
    }

    accountType === 'PERSONAL'
      ? navigate(routeNamesPaths.paPersonalDetail)
      : navigate(routeNamesPaths.businessApplication)

    setIsLoading(false)

    setShow(false)
  }

  return onCreateWallet
}
