import { Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  isAccordion?: boolean
}

export function HelpCenterContent({ isAccordion }: IProps) {
  const { t } = useTranslation('features')

  return (
    <>
      <Typography
        variant="body3"
        color="text.secondary"
        textTransform="uppercase"
        textAlign="start"
        sx={{ mb: 0.5 }}
      >
        {t('app-bar.help-center.contacts')}
      </Typography>

      <Link href="mailto:info@arbiex.eu" color="text.primary">
        <Typography variant={isAccordion ? 'body4' : 'body3'} textAlign="start">
          info@arbiex.eu
        </Typography>
      </Link>
    </>
  )
}
