import { useTheme } from '@mui/material'

import { TExchangeStatus } from '@common/types'

export function useExchangeStatusColors() {
  const theme = useTheme()

  const successColor = theme.palette.success.main
  const errorColor = theme.palette.error.main
  const secondaryColor = theme.palette.text.secondary
  const infoColor = theme.palette.info.main

  const colors: Record<TExchangeStatus, string> = {
    COMPLETED: successColor,
    CONFIRMED: infoColor,
    NEW: secondaryColor,
    REJECTED: errorColor,
    KRAKEN_ORDER_INITIATED: infoColor,
    KRAKEN_ORDER_CREATED: infoColor,
    KRAKEN_ORDER_CLOSED: infoColor,
    KRAKEN_WITHDRAW_CREATED: infoColor,
    KRAKEN_WITHDRAW_COMPLETED: infoColor,
    KRAKEN_WAIT_FOR_DEPOSIT: infoColor,
    SEND_TO_USER_WALLET_CREATED: infoColor,
    SEND_TO_CENTRAL_WALLET_CREATED: infoColor,
    SEND_TO_KRAKEN_WALLET_CREATED: infoColor,
    RECEIVED_TO_CENTRAL_WALLET: infoColor,
    FAILED: errorColor,
  }

  return colors
}
