import { IIdvInfo } from '@common/types'

import { apiErrorHandler } from '@shared/error-handlers'
import { api } from '@shared/http-instance'

export async function fetchUserIdvData() {
  try {
    const url = `/users/get-idv-data`

    const response = await api.get<IIdvInfo>(url)

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    apiErrorHandler(error)
  }
}
