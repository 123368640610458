import { IFiatBalance } from '@common/types'

import { api } from '@shared/http-instance'

export async function fetchFiatBalances(accountID?: string) {
  if (!accountID) {
    throw new Error('fetchFiatBalances required accountID to start fetching')
  }

  return api
    .get<IFiatBalance[]>(`/accounts/${accountID}/fiat-balances`)
    .then((response) => response.data)
}
