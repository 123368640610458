import { SxProps, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'

import { useTimer } from '@shared/hooks'
import { displayTimerCount } from '@shared/utils'

import { TimerText } from '../styled'

interface IProps {
  timer?: number
  onFinish?: () => void
  sx?: SxProps
}

export function Timer({ timer, onFinish, sx }: IProps) {
  const { time, setTime } = useTimer({ timer, onFinish: onFinish })

  useEffect(() => {
    timer && setTime(timer)
  }, [timer])

  return (
    <TimerText sx={{ mt: { xs: '36px', md: '28px' }, ...sx }}>
      <Trans ns="shared" i18nKey="otp dialog.timer text" />

      <> </>

      <Typography component="b" display="inline" fontSize="inherit">
        <Trans
          ns="shared"
          i18nKey={time < 60 ? 'otp dialog.seconds' : 'otp dialog.minutes'}
          components={{ span: <b style={{ textWrap: 'nowrap' }} /> }}
          values={{
            seconds: displayTimerCount(time, true),
          }}
        />
      </Typography>
    </TimerText>
  )
}
