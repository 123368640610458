import { LoadingButton } from '@mui/lab'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { EUserConfigKeys } from '@common/types'

import { updateConfig } from '@entities/configs'

import { useAuth } from '@shared/auth'
import { externalLinks } from '@shared/constants'

interface IProps {
  blocked: boolean
}

export function BlockedDialog({ blocked }: IProps) {
  const [termsAndPolicy, setTermsAndPolicy] = useState<{
    terms: boolean
    policy: boolean
  }>({ terms: false, policy: false })

  const { t } = useTranslation(['features', 'shared'])

  const { store: userStore } = useAuth()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)

      const response = await updateConfig({
        userId: userStore.user?.id,
        key: EUserConfigKeys.TERMS_AND_PRIVACY,
        value: {
          blocked: false,
        },
      })

      if (userStore.user) {
        userStore.setUser({
          ...userStore.user,
          configs: userStore.user.configs.map((config) => {
            if (config.key === EUserConfigKeys.TERMS_AND_PRIVACY) {
              return {
                key: EUserConfigKeys.TERMS_AND_PRIVACY,
                value: response,
              }
            }

            return config
          }),
        })
      }
    } catch {
      toast.error(t('shared:errors.oops'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      fullWidth
      open={blocked}
      sx={{
        '& .MuiPaper-root': {
          px: { xs: 2, md: 5 },
          py: { xs: 1.5, md: 4.5 },
          maxWidth: 642,
          borderRadius: 2,
          position: 'relative',
        },
      }}
    >
      <DialogTitle
        fontSize={{ xs: 20, md: 24 }}
        fontWeight={{ xs: 600 }}
        sx={{ p: 0, mb: 2 }}
      >
        {t('blocked-dialog.title', { name: userStore.user?.fullName })}
      </DialogTitle>

      <DialogContent
        sx={{
          bgcolor: 'background.default',
          p: '16px !important',
          borderRadius: 2,
        }}
      >
        <Typography variant="body1" whiteSpace="pre-wrap">
          <Trans
            ns="features"
            i18nKey="blocked-dialog.reason"
            components={{
              a: (
                <Link
                  href={externalLinks.termsAndConditions}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ display: 'inline', color: 'primary.dark' }}
                />
              ),
              a1: (
                <Link
                  href={externalLinks.privacyPolicyArbiex}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ display: 'inline', color: 'primary.dark' }}
                />
              ),
            }}
          />
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={<Checkbox />}
            onChange={(_, value) =>
              setTermsAndPolicy((prev) => ({ ...prev, terms: value }))
            }
            label={
              <Trans
                ns="features"
                i18nKey="blocked-dialog.agree-terms"
                components={{
                  a: (
                    <Link
                      href={externalLinks.termsAndConditions}
                      target="_blank"
                      rel="noreferrer"
                      sx={{ display: 'inline', color: 'primary.dark' }}
                    />
                  ),
                }}
              />
            }
          />

          <FormControlLabel
            control={<Checkbox />}
            onChange={(_, value) =>
              setTermsAndPolicy((prev) => ({ ...prev, policy: value }))
            }
            label={
              <Trans
                ns="features"
                i18nKey="blocked-dialog.agree-privacy"
                components={{
                  a: (
                    <Link
                      href={externalLinks.privacyPolicyArbiex}
                      target="_blank"
                      rel="noreferrer"
                      sx={{ display: 'inline', color: 'primary.dark' }}
                    />
                  ),
                }}
              />
            }
          />
        </FormGroup>

        <LoadingButton
          fullWidth
          disabled={!termsAndPolicy.terms || !termsAndPolicy.policy}
          loading={isLoading}
          onClick={handleConfirm}
          variant="contained"
          size="large"
        >
          {t('blocked-dialog.button')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
