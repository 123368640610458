import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Box, useTheme } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

import { countries, ICountry } from '@common/constants'

import { IInputProps } from '@shared/types'

import { optionsToGetCountryByNameOrAbrev } from '../../model'
import { TextFieldStyledLabel, StyledTextField } from '../../styled'

interface IProps extends IInputProps<unknown[]> {
  onChange?: (...event: unknown[]) => void
  variant?: 'filled' | 'outlined' | 'standard'
  listMode?: 'countries' | 'countryCodes'
}

export const CountrySelectInput = forwardRef<HTMLInputElement, IProps>(
  function Named(props: IProps, ref) {
    const {
      placeholder,
      error,
      helperText,
      label,
      onChange,
      onBlur,
      sx,
      value,
      variant,
      name,
      disabled,
      fullWidth,
      listMode,
    } = props

    const theme = useTheme()

    const [autocompleteValue, setAutocompleteValue] = useState<ICountry | null>(
      countries.find((item) => item.abbreviation === value) || null
    )

    useEffect(() => {
      setAutocompleteValue(
        countries.find((item) => item.abbreviation === value) || null
      )
    }, [value])

    return (
      <Box sx={sx}>
        <TextFieldStyledLabel
          className="label"
          disabled={disabled}
          error={error}
        >
          {label}
        </TextFieldStyledLabel>

        <Autocomplete
          disabled={disabled}
          fullWidth={fullWidth}
          value={autocompleteValue}
          defaultValue={null}
          clearOnBlur
          disablePortal
          options={countries}
          getOptionLabel={(option) =>
            !!listMode && listMode === 'countryCodes'
              ? option.abbreviation
              : option.name
          }
          filterOptions={optionsToGetCountryByNameOrAbrev}
          popupIcon={
            !disabled && (
              <ExpandMoreIcon sx={{ color: theme.palette.primary.dark }} />
            )
          }
          onChange={(_, option) => {
            if (onChange) {
              onChange(option?.abbreviation)
            }
          }}
          onBlur={onBlur}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              inputRef={ref}
              variant={variant}
              fullWidth={fullWidth}
              placeholder={placeholder}
              error={error}
              name={name}
              helperText={helperText || ''}
              InputProps={params.InputProps}
            />
          )}
        />
      </Box>
    )
  }
)
