import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Box, SxProps } from '@mui/material'
import { useEffect, useState, FocusEvent, forwardRef } from 'react'

import { TLegalDocumentType, TPersonDocumentType } from '@common/types'

import {
  TextFieldStyledLabel,
  StyledAutocompleteDropdownPaper,
  StyledTextField,
} from '../../styled'

export type TDocumentPurpose =
  | 'personal'
  | 'business'
  | 'proofOfAddress'
  | 'verification'

interface IProps {
  name?: string
  onChange?: (...event: unknown[]) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  value?: string
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string | boolean
  sx?: SxProps
  variant?: 'filled' | 'outlined' | 'standard'
  purpose?: TDocumentPurpose
  disabled?: boolean
}

type TDocTypesSelectOption = {
  type: TLegalDocumentType | TPersonDocumentType
  name: string
}

export const DocumentSelectInput = forwardRef<HTMLInputElement, IProps>(
  function Named(props, ref) {
    const {
      name,
      placeholder,
      error,
      helperText,
      label,
      onChange,
      sx,
      value,
      variant,
      purpose,
      onBlur,
      disabled,
    } = props

    const documentTypes = getDocumentTypesList(purpose)

    const [autocompleteValue, setAutocompleteValue] =
      useState<TDocTypesSelectOption | null>(
        documentTypes.find((item) => item.type === value) || null
      )

    useEffect(() => {
      setAutocompleteValue(
        documentTypes.find((item) => item.type === value) || null
      )
    }, [value])

    return (
      <Box
        sx={{ '& .MuiAutocomplete-root .MuiInputBase-root': { gap: 0 }, ...sx }}
      >
        <TextFieldStyledLabel disabled={disabled} error={error}>
          {label}
        </TextFieldStyledLabel>

        <Autocomplete
          disabled={disabled}
          fullWidth
          value={autocompleteValue}
          defaultValue={null}
          onBlur={onBlur}
          clearOnBlur
          disablePortal
          options={documentTypes}
          getOptionLabel={(option) => option.name}
          popupIcon={!disabled && <ExpandMoreIcon sx={{ color: '#009EE0' }} />}
          PaperComponent={({ children }) => (
            <StyledAutocompleteDropdownPaper>
              {children}
            </StyledAutocompleteDropdownPaper>
          )}
          onChange={(_, option) => {
            if (onChange) {
              onChange(option?.type)
            }
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              name={name}
              variant={variant}
              fullWidth
              placeholder={placeholder}
              error={error}
              helperText={helperText || ''}
              ref={ref}
              InputProps={{
                ...params.InputProps,
                startAdornment: <Box sx={{ width: 0, opacity: 0 }} />,
              }}
              sx={{
                '& input': {
                  fontWeight: 500,
                },
                '& fieldset': {
                  borderColor: autocompleteValue ? '#009EE0' : 'auto',
                },
              }}
            />
          )}
        />
      </Box>
    )
  }
)

function getDocumentTypesList(
  purpose?: TDocumentPurpose
): TDocTypesSelectOption[] {
  switch (purpose) {
    case 'personal':
      return [
        { type: 'PASSPORT', name: 'Passport' },
        { type: 'ID', name: 'ID Card' },
      ]
    case 'business':
      return [{ type: 'COMPANY_EXTRACT', name: 'COMPANY EXTRACT' }]
    case 'verification':
    default:
      return []
  }
}
