import { Skeleton } from '@mui/material'
import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CustomModal,
  ModalTitleWithWarning,
  OperationInfoList,
} from '@shared/ui'

import { ITransactionDetailsModalRef, useTransaction } from '../model'

import { warningTitleSx } from './styled'

// import { SpamCheckbox } from './components'

interface IProps {
  refetchCryptoTransactions: () => void
}

export const TransactionDetailsModal = forwardRef<
  ITransactionDetailsModalRef,
  IProps
>(
  function CryptoTransactionDetailsModal(
    // {
    /* refetchCryptoTransactions */
    // },
    _,
    ref
  ) {
    const { t } = useTranslation('widgets')

    const {
      cryptoTransactionDetailsForRender,
      handleClose,
      handleOpen,
      isLoading,
      isOpen,
      isSpam,
      // refetch,
      transactionId,
    } = useTransaction()

    useImperativeHandle(ref, () => ({
      handleOpen,
    }))

    return (
      <CustomModal
        onClose={handleClose}
        open={isOpen}
        error={isSpam}
        sx={{
          '& .MuiDialogContent-root': {
            paddingBottom: { md: '36px' },
          },
        }}
      >
        <ModalTitleWithWarning
          title={t('transactions-list.transaction-details.title')}
          id={transactionId || ''}
          copyButton
          warning={isSpam}
          warningMessage={
            isSpam
              ? t('crypto-transactions.transaction-details.warning-message')
              : undefined
          }
          sx={isSpam ? warningTitleSx : { mb: 2 }}
        />

        {/* <SpamCheckbox
        transactionId={transactionId}
        refetchCryptoTransactions={refetchCryptoTransactions}
        refetchTransactionDetails={refetch}
        spam={isSpam}
        sx={{ justifyContent: 'flex-end' }}
      /> */}

        {!isLoading ? (
          <OperationInfoList
            operationInfo={cryptoTransactionDetailsForRender}
          />
        ) : (
          <Skeleton height={64} sx={{ transform: 'initial' }} />
        )}
      </CustomModal>
    )
  }
)
