import { Typography } from '@mui/material'
import { memo, forwardRef, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { TCurrency } from '@common/types'

import { FeeInfo } from '@widgets/exchange/ui/components'

import { useFeePlans } from '@entities/fee-plan'

import { useAccountStore } from '@shared/store'
import { AttentionMessageWrapper } from '@shared/ui'

interface IProps {
  currency: TCurrency
}

export const ImportantInfoPart = memo(
  forwardRef<HTMLDivElement, IProps>(function ImportantInfoPart(
    { currency },
    ref
  ) {
    const activeAccount = useAccountStore((s) => s.account)

    const { isLoading, data } = useFeePlans(activeAccount?.id)

    const { t } = useTranslation('pages')

    const currentPlan = useMemo(() => {
      if (!data || isLoading) {
        return []
      }

      const planTypeElement = data.find(
        (feePlan) => feePlan.currency === currency
      )

      return planTypeElement?.scales || []
    }, [data, isLoading, currency])

    const valuesForTranslate = {
      minSum: currentPlan[0]?.min,
      maxSum: currentPlan[currentPlan.length - 1]?.max
        ? `${currentPlan[currentPlan.length - 1]?.max} EUR`
        : t('exchange.total-message'),
    }

    return (
      <AttentionMessageWrapper ref={ref}>
        <Typography variant="body1" component="p" mb={2}>
          {t('exchange.fee-plan')}
        </Typography>

        <FeeInfo feePlan={data?.[0]} size="medium" sx={{ mb: 3 }} />

        <Trans
          i18nKey="exchange.alert"
          ns="pages"
          values={valuesForTranslate}
          components={{
            p: <Typography variant="body2" component="p" mb={2} />,
            strong: <Typography variant="body1" component="span" />,
            nowrap: <span style={{ whiteSpace: 'wrap' }} />,
          }}
        />
      </AttentionMessageWrapper>
    )
  })
)
