import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BlockingWindow } from '@features/blocking-window'

import { externalLinks } from '@shared/constants'
import { PaperRounded } from '@shared/ui'

interface IProps {
  verified: boolean
  blocked: boolean
}

export function BlockingWindows({ blocked, verified }: IProps) {
  const { t } = useTranslation('app')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {!verified && (
        <PaperRounded transparent={!mdAndUp} disableGutters={!mdAndUp}>
          <BlockingWindow
            title={t('not verified.title')}
            buttonText={t('not verified.button')}
            description={t('not verified.description')}
            href={externalLinks.idv}
          />
        </PaperRounded>
      )}

      {blocked && (
        <PaperRounded transparent={!mdAndUp} disableGutters={!mdAndUp}>
          <BlockingWindow
            title={t('blocking-window.title')}
            buttonText={t('blocking-window.button')}
            description={t('blocking-window.description')}
            href={externalLinks.support}
            buttonVariant="text"
            error
          />
        </PaperRounded>
      )}
    </>
  )
}
