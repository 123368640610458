import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUploadFileSettings } from '@shared/settings'
import { formatAllowedFileTypes, formatBytes } from '@shared/utils'

import { ReactComponent as UploadCloudTiny } from './assets/upload-cloud-tiny.svg'
import { ReactComponent as WarningIcon } from './assets/warning-icon.svg'
import {
  StyledDragNDropAreaWrapTiny,
  StyledDragNDropBox,
  StyledTextDropWrap,
  CloudIconWrapper,
} from './styled'

interface IProps {
  onClickUpload: () => void
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  onSelectFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  uploadDocTitle?: string
  underAreaText?: string
}

export const DragNDropAreaTiny = forwardRef(function DragNDropArea(
  {
    onClickUpload,
    onDrop,
    onDragOver,
    onSelectFiles,
    error,
    helperText,
    uploadDocTitle,
    underAreaText,
  }: IProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation('shared')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { data: settings, refetch } = useUploadFileSettings()
  const allowedTypes = settings?.allowedTypes || []

  useEffect(() => {
    !settings && refetch()
  }, [settings])

  return (
    <>
      <StyledDragNDropBox
        isError={error}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onClick={onClickUpload}
        tabIndex={0}
      >
        <StyledDragNDropAreaWrapTiny variant="outlined" error={error}>
          <input
            onChangeCapture={onSelectFiles}
            ref={ref}
            type="file"
            name="files"
            accept={allowedTypes.join(',')}
            multiple
            hidden
          />

          <CloudIconWrapper
            error={error}
            justifyContent={'flex-start'}
            alignItems={'center'}
            className={'cloud_icon'}
          >
            <UploadCloudTiny />
          </CloudIconWrapper>

          <Box
            color={{
              xs: error ? theme.palette.error.main : undefined,
              md: theme.palette.common.white,
            }}
          >
            <Typography variant={'body3'} component="p">
              {!uploadDocTitle ? underAreaText : uploadDocTitle}
            </Typography>

            <Typography variant={'body12'}>
              {t('drag-n-drop.Only formats like', {
                formats: formatAllowedFileTypes(allowedTypes),
                size:
                  settings?.maxFileSize && formatBytes(settings.maxFileSize),
              })}
            </Typography>
          </Box>
        </StyledDragNDropAreaWrapTiny>

        {mdAndUp && (
          <StyledTextDropWrap>
            <Typography
              className={'drop_file'}
              variant={'body3'}
              sx={{
                color: error
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
              }}
            >
              {t('drag-n-drop.Drop file')}
            </Typography>
          </StyledTextDropWrap>
        )}
      </StyledDragNDropBox>

      {!mdAndUp && (
        <Typography
          textAlign={'center'}
          variant={'body16'}
          sx={{
            color: error
              ? theme.palette.error.main
              : theme.palette.text.primary,
          }}
        >
          {underAreaText ? underAreaText : t('drag-n-drop.Invoice contract')}
        </Typography>
      )}

      {error && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className="error_message"
        >
          <Box>
            <WarningIcon className="warning_icon" />
          </Box>

          <Typography variant={'body2'} color={theme.palette.error.main}>
            {helperText || t('validation.required')}
          </Typography>
        </Stack>
      )}
    </>
  )
})
