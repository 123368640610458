import { Avatar } from '@mui/material'

import { useAuthStore } from '@shared/auth'
import { useAccountStore } from '@shared/store'
import { stringToColor } from '@shared/utils'

export function UserAvatar() {
  const userInitials = useAuthStore((state) => state.getUserInitials)()
  const userFullName = useAuthStore((state) => state.getUserFullName)()
  const currentAccount = useAccountStore((state) => state.account)

  return (
    <Avatar
      variant="rounded"
      sx={{
        position: currentAccount ? 'absolute' : 'initial',
        right: 2,
        backgroundColor: stringToColor(userFullName),
      }}
      alt={userFullName}
    >
      {userInitials}
    </Avatar>
  )
}
