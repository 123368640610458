import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AttentionIcon } from './assets/attention.icon.svg'
import { StyledButton } from './styled'

interface IProps {
  message?: string
  onClick?: () => void
  sx?: SxProps<Theme>
}

export function AttentionMessageButton({
  message,
  onClick,
  children,
  sx,
}: IProps & PropsWithChildren) {
  const { t } = useTranslation('shared')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledButton
      disableRipple
      onClick={!mdAndUp ? onClick : undefined}
      sx={sx}
    >
      <AttentionIcon />

      <Box>
        {children}
        {message || (!children && t('attention-message.important'))}
      </Box>
    </StyledButton>
  )
}
