import { useMediaQuery, useTheme } from '@mui/material'
import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { TRouteNamesPaths, routeNamesPaths } from '@shared/constants'
import { TGeneralTemplateType } from '@shared/types'

import { ReactComponent as ArrowD } from './assets/arrow-d.svg'
import { ReactComponent as ArrowM } from './assets/arrow-m.svg'
import { ReactComponent as BalancesIcon } from './assets/balances.svg'
import { ReactComponent as DepositIcon } from './assets/deposit.svg'
import { ReactComponent as ExchangeHistoryIcon } from './assets/exchange-history.svg'
import { ReactComponent as ExchangeIcon } from './assets/exchange.svg'
import { ReactComponent as MoreIcon } from './assets/more.svg'
import { ReactComponent as TransactionsIcon } from './assets/transactions.svg'
import { ReactComponent as WithdrawIcon } from './assets/withdraw.svg'

type TButtonName =
  | 'withdraw'
  | 'deposit'
  | 'send'
  | 'receive'
  | 'exchange'
  | 'transactions'
  | 'exchangeHistory'
  | 'assets'
  | 'toCrypto'

interface IButtonData<T = TRouteNamesPaths> {
  icon?: ReactNode
  iconPos?: 'start' | 'end'
  href: T
}

type TButtonRecords = Record<TButtonName, IButtonData>

interface IProps {
  template: TGeneralTemplateType
}

export function useButtonsConfig({ template }: IProps) {
  const location = useLocation()
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const buttons: TButtonRecords = {
    assets: {
      icon: <BalancesIcon />,
      href: routeNamesPaths.assets,
    },
    withdraw: {
      icon: <WithdrawIcon />,
      href: routeNamesPaths.withdrawal,
    },
    deposit: {
      icon: <DepositIcon />,
      href: routeNamesPaths.deposit,
    },
    send: {
      icon: <WithdrawIcon />,
      href: routeNamesPaths.createTransaction,
    },
    receive: {
      icon: <DepositIcon />,
      href: routeNamesPaths.receive,
    },
    exchange: {
      icon: <ExchangeIcon />,
      href: routeNamesPaths.exchange,
    },
    transactions: {
      icon: <TransactionsIcon />,
      href: routeNamesPaths.transactions,
    },
    exchangeHistory: {
      icon: <ExchangeHistoryIcon />,
      href: routeNamesPaths.exchangeHistory,
    },
    toCrypto: {
      icon: mdAndUp ? <ArrowD /> : <ArrowM />,
      href: routeNamesPaths.assets,
    },
  }

  const firstPartButtons = (): Partial<TButtonRecords> => {
    if (template === 'wallet') {
      return {
        assets: buttons.assets,
        send: buttons.send,
        receive: buttons.receive,
        exchange: buttons.exchange,
      }
    }

    return {
      withdraw: buttons.withdraw,
      deposit: buttons.deposit,
    }
  }

  const secondPartButtons = (): Partial<TButtonRecords> => {
    if (template === 'wallet') {
      return mdAndUp
        ? {
            transactions: buttons.transactions,
            exchangeHistory: buttons.exchangeHistory,
          }
        : {}
    }

    return {}
  }

  const morePartButtons = (): Partial<TButtonRecords> => {
    if (template === 'wallet') {
      return {
        transactions: buttons.transactions,
        exchangeHistory: buttons.exchangeHistory,
      }
    }

    return {}
  }

  const arrayButtons = Object.entries(buttons)
  const firstArrayButtons = Object.entries(firstPartButtons())
  const secondArrayButtons = Object.entries(secondPartButtons())
  const moreArrayButtons = Object.entries(morePartButtons())

  const moreButton: IButtonData<TRouteNamesPaths[]> = {
    icon: <MoreIcon />,
    href: moreArrayButtons.map(([, item]) => item.href),
  }

  const checkActivePath = (pathname: string | string[]) => {
    if (Array.isArray(pathname)) {
      return pathname.some((path) => location.pathname.includes(path))
    }

    return location.pathname === pathname
  }

  return {
    buttons,
    arrayButtons,
    firstArrayButtons,
    secondArrayButtons,
    moreArrayButtons,
    moreButton,
    checkActivePath,
  }
}
