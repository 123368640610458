import { Search } from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/material'
import { useMemo } from 'react'

import { TBlockchain } from '@common/types'

import { useMobileWebviewStore } from '@shared/hooks'
import { CopyButton } from '@shared/ui'

interface IHash {
  hash: string
  blockchain: TBlockchain
}

interface IWithoutHash {
  hash?: never
  blockchain?: never
}

interface IProps {
  copyableElementName: string
  value?: string
  spam?: boolean
}

type TProps = IProps & (IHash | IWithoutHash)

export function InfoValueWithCopySearchButtons({
  copyableElementName,
  value,
  hash,
  spam,
  blockchain,
}: TProps) {
  const isMobile = useMobileWebviewStore((state) => state.isMobile)

  const sendPostMessageLink = useMobileWebviewStore(
    (state) => state.sendPostMessageLink
  )

  const tronScanLink = useMemo(() => {
    switch (blockchain) {
      case 'TRON':
        return `https://tronscan.org/#/transaction/${hash}`
      case 'ETHEREUM':
        return `https://etherscan.io/tx/${hash}`
      default:
        return ''
    }
  }, [hash])

  return (
    <Stack direction="row" alignItems="center" component="span" gap={0.5}>
      {!!hash && (
        <IconButton
          href={isMobile ? undefined : tronScanLink}
          onClick={() => {
            isMobile && sendPostMessageLink(tronScanLink)
          }}
          target="_blank"
          size="small"
        >
          <Search />
        </IconButton>
      )}

      <Box component="span" sx={{ maxWidth: 440, wordBreak: 'break-word' }}>
        {value}
      </Box>

      {!spam && (
        <CopyButton value={value} copyableElementName={copyableElementName} />
      )}
    </Stack>
  )
}
