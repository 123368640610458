import { SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  sx?: SxProps
}

export function WithdrawalConfirmationTitle({ sx }: IProps) {
  const { t } = useTranslation('features')

  return (
    <Typography variant="subtitle1" sx={sx}>
      {t('withdraw.confirm-operation')}
    </Typography>
  )
}
