import { Stack, Typography, TypographyOwnProps } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

import { TBlockchain } from '@common/types'

import { TCryptoCoinKey, grey, tokenStandard } from '@shared/constants'

import { currentCoin, currentColorCoin, currentIssueCoin } from './utils'

interface IProps {
  name?: TCryptoCoinKey
  sx?: SystemStyleObject
  withName?: boolean
  error?: boolean
  nameVariant?: TypographyOwnProps['variant']
  blockchain?: TBlockchain
  rate?: string | number | (() => string)
  isColor?: boolean
}

export function CryptoCoinIcon({
  name = 'USDT',
  sx,
  withName,
  error,
  nameVariant = 'subtitle1',
  blockchain,
  rate,
  isColor,
}: IProps) {
  const isColorCoin = isColor ? currentColorCoin(name) : currentCoin(name)

  const getRate = () => {
    if (typeof rate === 'string' || typeof rate === 'number') {
      return rate
    }

    return rate !== undefined && rate()
  }

  return (
    <Stack direction={'row'} alignItems={'center'} gap={1} sx={sx}>
      {error ? currentIssueCoin(name) : isColorCoin}

      {withName && (
        <Stack justifyContent={'space-between'}>
          <Stack direction={'row'} gap={0.3}>
            <Typography variant={nameVariant}>{name}</Typography>

            {blockchain && name !== 'EUR' && (
              <Typography variant="body4" minWidth={'max-content'}>
                {tokenStandard[blockchain]}
              </Typography>
            )}
          </Stack>

          {rate && (
            <Typography
              variant="body9"
              minWidth={'max-content'}
              color={grey[500]}
            >
              {getRate()} EUR
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}
