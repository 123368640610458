import { LoadingButton } from '@mui/lab'
import {
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TAccountVariant } from '@common/types'

import { useCreateWalletStore } from '@entities/create-wallet'

import { CustomModal } from '@shared/ui'

import { useCreateWallet } from '../../model'

import { ChooseAccount } from './components'
import { createWalletModalSx } from './styled'

export function CreateWalletModal() {
  const { t } = useTranslation('features')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { setShow, show } = useCreateWalletStore()

  const [accountType, setAccountType] = useState<TAccountVariant | null>(null)
  const [businessDir, setBusinessDir] = useState<'auth' | 'dir' | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onCreateWallet = useCreateWallet({
    accountType,
    setIsLoading,
  })

  const isAccountTypeValid = useMemo(
    () =>
      accountType === 'PERSONAL' || (accountType === 'BUSINESS' && businessDir),
    [accountType, businessDir]
  )

  const reset = () => {
    setAccountType(null)

    setBusinessDir(null)
  }

  useEffect(() => {
    if (!show) {
      setTimeout(reset, 300)
    }
  }, [show])

  return (
    <>
      <CustomModal
        open={show}
        onClose={() => setShow(false)}
        sx={createWalletModalSx}
      >
        <Typography variant={mdAndUp ? 'h1' : 'subtitle3'} mb={3}>
          {t('create wallet.title')}
        </Typography>

        <Stack>
          <ChooseAccount
            accountType={accountType}
            setAccountType={setAccountType}
            businessDir={businessDir}
            setBusinessDir={setBusinessDir}
          />

          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => setShow(false)}
                size="large"
              >
                {t('create wallet.cancel')}
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <LoadingButton
                disabled={!isAccountTypeValid}
                loading={isLoading}
                variant="contained"
                color="primary"
                fullWidth
                onClick={onCreateWallet}
                size="large"
              >
                {t('create wallet.title')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </CustomModal>
    </>
  )
}
