import { useMemo } from 'react'

import { TBlockchain } from '@common/types'

import { ReactComponent as EthereumIcon } from '../assets/ERC-20.svg'
import { ReactComponent as TronIconNoActive } from '../assets/TRC-20-no-active.svg' //Todo: Заменить эту и остальные иконки после возвращения Олега
import { ReactComponent as TronIconActive } from '../assets/TRC-20.svg'

interface IProps {
  type: TBlockchain
  isActive?: boolean
}

export function BlockchainIcon({ type, isActive }: IProps) {
  const tronIcon = useMemo(
    () => (isActive ? <TronIconActive /> : <TronIconNoActive />),
    [isActive]
  )

  return (
    <>
      {type === 'TRON' && tronIcon}
      {type === 'ETHEREUM' && <EthereumIcon />}
    </>
  )
}
