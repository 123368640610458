import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, SxProps } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface IProps {
  value?: string | null
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  color?: string
  sx?: SxProps
  copyableElementName?: string
}

export function CopyButton({
  disabled = false,
  value,
  color,
  size = 'small',
  sx,
  copyableElementName,
}: IProps) {
  const { t } = useTranslation('shared')

  const handleCopy = async (
    event: MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation()

    try {
      if (!value) {
        return
      }

      const input = document.createElement('input')

      input.value = value

      input.style.position = 'fixed'

      input.style.left = '-1000000000px'

      window.document.body.appendChild(input)

      input.focus()

      input.select()

      await navigator.clipboard.writeText(value)

      input.remove()

      if (copyableElementName) {
        toast.success(
          t('copy-button.This-copied-successfully', {
            this: copyableElementName,
          })
        )

        return
      }

      toast.success(t('copy-button.Copied successfully'))
    } catch {
      if (copyableElementName) {
        toast.error(
          t('copy-button.Error-to-copy-this', {
            this: copyableElementName,
          })
        )

        return
      }

      toast.error(t('copy-button.Error to copy'))
    }
  }

  return (
    <IconButton
      className="copyBtn"
      sx={{ color, ...sx }}
      size={size}
      onClick={handleCopy}
      disabled={disabled || !value}
    >
      <ContentCopyIcon />
    </IconButton>
  )
}
