import { styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  isBack?: boolean
}

export const SlideButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isBack',
})<IProps>(({ theme, isBack }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: 4,
  background: theme.palette.common.white + 'c4',
  cursor: 'pointer',
  width: 52,
  height: 64,
  transform: 'translateY(-50%)',
  position: 'absolute',
  transition: theme.transitions.create(['opacity', 'transform', 'background']),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:disabled': {
    display: 'none',
  },

  '&:hover': {
    background: grey[100],
  },

  top: '50%',

  ...(isBack && {
    left: '-50px !important',
  }),
  ...(!isBack && {
    left: 'unset !important',
    right: '-50px !important',
  }),

  '& svg': {
    fill: theme.palette.primary.dark,
    width: 24,
    height: 24,
  },
}))
