// import axios from 'axios'

import { configs } from '@shared/constants'

interface ISendErrorPayload {
  message: string
}

export async function sendError({ message }: ISendErrorPayload) {
  try {
    const { currentStage } = configs()

    if (currentStage === 'loc') {
      return
    }

    // eslint-disable-next-line no-console
    console.error(`${currentStage}: ${message}`)

    // await axios.post('https://api.dev.arbiex.eu/common/error', {
    //   message: `${currentStage}: ${message}`,
    // })
  } catch {
    // eslint-disable-next-line no-console
    console.log('cant send to slack')
  }
}
