import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const OperationInfoWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '24px',

    gap: '8px',

    backgroundColor: grey[50],
    borderRadius: '8px',
  },
}))
