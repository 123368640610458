import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IBusinessAccountApplicationShareholder } from '../types'

interface IBusinessValidationState {
  totalPercentage: number
  setTotalPercentage: (
    payload: IBusinessAccountApplicationShareholder[]
  ) => void

  isPercentageSumError: boolean
}

export const useBusinessValidationStore = create<IBusinessValidationState>()(
  devtools(
    (set, get) => ({
      totalPercentage: 0,
      setTotalPercentage: (shareholders) =>
        set({
          totalPercentage: shareholders.reduce((acc, shareholder) => {
            const shareholderTypeField =
              shareholder.type === 'PERSONAL' ? 'personal' : 'business'

            const percentageOwnership =
              shareholder[shareholderTypeField]?.percentageOwnership

            return acc + (Number(percentageOwnership) || 0)
          }, 0),

          ...(get().totalPercentage > 99 && !get().isPercentageSumError
            ? { isPercentageSumError: true }
            : undefined),
        }),

      isPercentageSumError: false,
    }),
    { name: 'business-validation-store' }
  )
)
