import { getValidDate } from './get-valid-date'

export const getTimeToLeftFromTimestamp = (timestamp: number) => {
  return Math.trunc((timestamp - new Date().getTime()) / 1000)
}

export const getTimeToLeftFromISODate = (isoDate: string) => {
  return Math.trunc(
    (getValidDate(isoDate).getTime() - new Date().getTime()) / 1000
  )
}

export const getOldTimeToLeft = (createdAt: string, TTL: number) => {
  const createdAtTimestamp = new Date(createdAt).getTime()

  return Math.trunc(
    (createdAtTimestamp + TTL * 1000 - new Date().getTime()) / 1000
  )
}
