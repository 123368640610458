import { styled } from '@mui/material'

import { AttentionMessageButton } from '@shared/ui'

export const StyledAttentionMessage = styled(AttentionMessageButton)(
  ({ theme }) => ({
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      position: 'absolute',
      top: 0,
      right: 20,
    },
  })
)
