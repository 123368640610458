import { Box } from '@mui/material'
import { format } from 'date-fns'
import Decimal from 'decimal.js'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ITransactionDetails, TTransactionsStatus } from '@common/types'

import { IOperationInfo } from '@shared/ui'

import { InfoValueWithCopySearchButtons } from '../../ui'

const successColor = 'success.main'
const errorColor = 'error.main'
const infoColor = 'info.main'

export function useTransactionForRender(transaction?: ITransactionDetails) {
  const { t } = useTranslation(['widgets', 'shared'])

  const colors: Record<TTransactionsStatus, string> = {
    COMPLETED: successColor,
    CANCELED: errorColor,
    FAILED: errorColor,
    PENDING: infoColor,
    CREATED: infoColor,
    ANALYZED: infoColor,
    REJECTED_BY_AML: errorColor,
    RISKED: infoColor,
  }

  const cryptoTransactionDetailsForRender: IOperationInfo[] = useMemo(() => {
    if (!transaction) {
      return []
    }

    const {
      amount,
      asset,
      blockchain,
      createdAt,
      direction,
      hash,
      networkFee,
      receiver,
      scope,
      sender,
      spam,
      status,
    } = transaction

    return [
      {
        keyName: t(
          `crypto-transactions.transaction-details.${
            direction === 'IN' ? 'received-amount' : 'sent-amount'
          }`
        ),
        value: `${amount} ${asset}`,
      },
      {
        keyName: t('crypto-transactions.transaction-details.blockchain'),
        value: blockchain,
      },
      {
        keyName: t(
          'crypto-transactions.transaction-details.transaction-category'
        ),
        value: t(`crypto-transactions.transaction-details.${scope}`),
      },

      ...(networkFee
        ? [
            {
              keyName: t('crypto-transactions.transaction-details.network-fee'),
              value: new Decimal(networkFee).toFixed(),
            },
          ]
        : []),
      {
        keyName: t('crypto-transactions.transaction-details.status'),
        value: (
          <Box component="span" color={colors[status]}>
            {t(`crypto-transactions.transaction-details.${status}`)}
          </Box>
        ),
      },
      {
        keyName: t('crypto-transactions.transaction-details.sender'),
        value: (
          <InfoValueWithCopySearchButtons
            spam={spam}
            value={sender}
            copyableElementName={t(
              'shared:copy-button.Copied-elements.address'
            )}
          />
        ),
      },
      {
        keyName: t('crypto-transactions.transaction-details.receiver'),
        value: (
          <InfoValueWithCopySearchButtons
            value={receiver}
            copyableElementName={t(
              'shared:copy-button.Copied-elements.address'
            )}
          />
        ),
      },
      {
        keyName: t('crypto-transactions.transaction-details.date'),
        value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
      },
      ...(hash
        ? [
            {
              keyName: t('crypto-transactions.transaction-details.hash'),
              value: (
                <InfoValueWithCopySearchButtons
                  hash={hash}
                  value={hash}
                  blockchain={blockchain}
                  copyableElementName={t(
                    'crypto-transactions.transaction-details.hash'
                  )}
                />
              ),
            },
          ]
        : []),
    ]
  }, [transaction])

  return cryptoTransactionDetailsForRender
}
