import to from 'await-to-js'
import { AxiosError } from 'axios'
import { Dispatch, memo, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ISingleErrorResponse, TOtpError } from '@common/types'

import {
  cancelWithdrawal,
  confirmWithdrawalSendSms,
  confirmWithdrawalVerifySms,
  TWithdrawalForm,
} from '@entities/withdrawal'

import { TConfirmationOptions } from '@shared/types'
import {
  OtpDialog,
  TOtpConfirmationMethod,
  TOtpConfirmationStatuses,
  TOtpDialogCloseTarget,
  TOtpResendCodeMethod,
} from '@shared/ui'

interface IProps {
  withdrawConfirmationOptions: TConfirmationOptions
  setIsShowOtp: (value: boolean) => void
  setOpenSuccess: (value: boolean) => void
  setAttemptsCounter: Dispatch<SetStateAction<number>>
  setWithdrawConfirmationOptions: (payload: TConfirmationOptions) => void
  isShowOtp: boolean
  attemptsCounter: number
}

export const WithdrawalConfirmationOtp = memo(
  function WithdrawalConfirmationOtp({
    withdrawConfirmationOptions,
    setIsShowOtp,
    setOpenSuccess,
    setAttemptsCounter,
    setWithdrawConfirmationOptions,
    attemptsCounter,
    isShowOtp,
  }: IProps) {
    const { t } = useTranslation(['features', 'shared'])

    const { reset } = useFormContext<TWithdrawalForm>()

    const otpErrorResolver = (error?: TOtpError): TOtpConfirmationStatuses => {
      switch (error) {
        case 'OTP_INVALID':
          return 'NOT_VALID'
        // TODO: backend returns only "OTP_INVALID". Need to add "OTP_EXPIRED" and "MAX_ATTEMPTS"
        case 'OTP_EXPIRED':
          return 'EXPIRE'
        default:
          throw new Error('Something went wrong. Please try again later.')
      }
    }

    const confirmationMethod: TOtpConfirmationMethod = async ({ otp }) => {
      if (!withdrawConfirmationOptions) {
        throw new Error('Withdraw is not defined')
      }

      const [error] = await to(
        confirmWithdrawalVerifySms({
          withdrawalId: withdrawConfirmationOptions.id,
          otpCode: otp,
        })
      )

      if (!error) {
        return 'SUCCESS'
      }

      return otpErrorResolver(
        (error as AxiosError<ISingleErrorResponse<TOtpError>>).response?.data
          .error
      )
    }

    const onConfirm = () => {
      toast.success(t('withdraw.success'))

      setIsShowOtp(false)

      reset()

      setTimeout(setOpenSuccess, 300, true)
    }

    const resendOtp: TOtpResendCodeMethod = async () => {
      if (!withdrawConfirmationOptions) {
        throw new Error('Withdraw is not defined')
      }

      const [error, response] = await to(
        confirmWithdrawalSendSms(withdrawConfirmationOptions.id)
      )

      setAttemptsCounter((prev) => prev + 1)

      if (error) {
        toast.error(t('withdraw.errors.failed-to-send'))

        return { result: false, ttl: 0 }
      }

      setWithdrawConfirmationOptions({
        id: withdrawConfirmationOptions.id,
        end: new Date(response.confirmation.end).getTime(),
        attempts: response.confirmation.tries,
      })

      return {
        result: true,
        ttl: new Date(response.confirmation.end).getTime(),
      }
    }

    const handleCloseOtp = (target: TOtpDialogCloseTarget) => {
      const isCLosedByUser =
        target === 'backdropClick' ||
        target === 'closeButton' ||
        target === 'escapeKeyDown'

      if (isCLosedByUser) {
        handleCancelWithdraw()
      }

      reset()

      setIsShowOtp(false)
    }

    const handleCancelWithdraw = async () => {
      try {
        if (!withdrawConfirmationOptions) {
          throw new Error('Withdraw is not defined')
        }

        await cancelWithdrawal(withdrawConfirmationOptions.id)

        toast.success(t('withdraw.cancel-success'))
      } catch {
        toast.error(t('shared:errors.oops'))
      }
    }

    return (
      <OtpDialog
        show={isShowOtp}
        onClose={handleCloseOtp}
        confirmationMethod={confirmationMethod}
        onConfirm={onConfirm}
        resendCodeMethod={
          attemptsCounter >= (withdrawConfirmationOptions?.attempts || 0)
            ? undefined
            : resendOtp
        }
        timer={withdrawConfirmationOptions?.end}
      />
    )
  }
)
