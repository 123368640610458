import {
  IVerifyTransactionOtpPathParams,
  IVerifyOtpRequestBody,
} from '@common/types'

import { api } from '@shared/http-instance'

export function transactionVerifyOtp({
  otpCode,
  transactionId,
}: IVerifyTransactionOtpPathParams & IVerifyOtpRequestBody) {
  return api.put(`/transactions/${transactionId}/verify-otp`, { otpCode })
}
