import { InfoOutlined } from '@mui/icons-material'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TAccountVariant } from '@common/types'

import { useAccountList } from '@entities/account'

import { useAccountStore } from '@shared/store'

import { ReactComponent as BusinessIcon } from '../../assets/business.svg'
import { ReactComponent as PersonalIcon } from '../../assets/personal.svg'
import { cardsContainerPaddingX } from '../constants'

import { ChooseButton } from './choose-button'

interface IProps {
  accountType: TAccountVariant | null
  setAccountType: (value: TAccountVariant | null) => void
  businessDir: 'auth' | 'dir' | null
  setBusinessDir: (value: 'auth' | 'dir' | null) => void
}

export function ChooseAccount({
  accountType,
  setAccountType,
  businessDir,
  setBusinessDir,
}: IProps) {
  const { t } = useTranslation('features')
  const { data, isLoading } = useAccountList()
  const getAccountName = useAccountStore((s) => s.getAccountName)

  const personalAccountName = useMemo(
    () => getAccountName('PERSONAL', data),
    [data]
  )

  const businessAccountName = useMemo(
    () => getAccountName('BUSINESS', data),
    [data]
  )

  const accountInReviewStatus = useMemo(() => {
    return {
      personal: data?.find((account) => account.variant === 'PERSONAL')?.status,
      business: data?.find((account) => account.variant === 'BUSINESS')?.status,
    }
  }, [data])

  const checkIsLoading = (accountName?: string) => {
    return accountName === undefined ? isLoading : false
  }

  useEffect(() => {
    setBusinessDir(null)
  }, [accountType])

  return (
    <Box py={2.5}>
      <Typography variant="body3" mb={2}>
        {t('create wallet.choose account.title')}
      </Typography>

      <Box px={cardsContainerPaddingX}>
        <Grid
          container
          spacing={2}
          justifyContent={{ xs: 'space-between', md: 'center' }}
        >
          <Grid item xs={6}>
            <ChooseButton
              icon={<PersonalIcon />}
              text={t('create wallet.choose account.personal')}
              active={accountType === 'PERSONAL'}
              onClick={() => setAccountType('PERSONAL')}
              accountName={personalAccountName}
              inReview={accountInReviewStatus.personal === 'NEW'}
              loading={checkIsLoading(personalAccountName)}
              disabled={accountInReviewStatus.personal === 'APPROVED'}
            />
          </Grid>

          <Grid item xs={6}>
            <ChooseButton
              icon={<BusinessIcon />}
              text={t('create wallet.choose account.business')}
              active={accountType === 'BUSINESS'}
              onClick={() => setAccountType('BUSINESS')}
              accountName={businessAccountName}
              inReview={accountInReviewStatus.business === 'NEW'}
              loading={checkIsLoading(businessAccountName)}
              disabled={accountInReviewStatus.business === 'APPROVED'}
            />
          </Grid>
        </Grid>
      </Box>

      <Collapse in={accountType === 'BUSINESS'}>
        <Box px={cardsContainerPaddingX} pt={2}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <ChooseButton
                icon={<InfoOutlined sx={{ width: 16, height: 16 }} />}
                text={t('create wallet.choose account.authorized person')}
                active={businessDir === 'auth'}
                disabled
                onClick={() => setBusinessDir('auth')}
                selectType={'accountDir'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ChooseButton
                icon={<InfoOutlined sx={{ width: 16, height: 16 }} />}
                text={t('create wallet.choose account.authorized director')}
                selectType={'accountDir'}
                active={businessDir === 'dir'}
                onClick={() => setBusinessDir('dir')}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}
