import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IAccountU, TAccountVariant } from '@common/types'

export type TIsApproved = Record<string, boolean> // by accountId

interface IAccountState {
  account: IAccountU | null
  getAccountName: (
    variant: TAccountVariant,
    accounts?: IAccountU[]
  ) => string | undefined
  setAccount: (account: IAccountU | null) => void

  isApproved: TIsApproved
  setIsApproved: (payload: TIsApproved) => void
}

export const useAccountStore = create<IAccountState>()(
  devtools(
    (set) => ({
      account: null,
      getAccountName: (variant: TAccountVariant, accounts?: IAccountU[]) => {
        if (variant === 'BUSINESS') {
          const accountName = accounts?.find(
            (account) => account.variant === 'BUSINESS'
          )?.businessApplication?.name

          return accountName
        }

        if (variant === 'PERSONAL') {
          const personalApplication = accounts?.find(
            (account) => account.variant === 'PERSONAL'
          )?.personalApplication

          return personalApplication
            ? `${personalApplication.firstName} ${personalApplication.lastName}`
            : undefined
        }

        return
      },
      setAccount: (account) => set({ account }),

      isApproved: {},
      setIsApproved: (isApproved) => set({ isApproved }),
    }),
    {
      name: 'accountStore',
    }
  )
)
