import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Button,
  ClickAwayListener,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { accordionIconSize } from '../../model'

import { ReactComponent as QuestionSign } from './assets/question-sign.svg'
import { HelpCenterContent } from './components'
import { StyledPopper } from './styled'

interface IProps {
  sx?: SxProps
}

export function HelpCenterPopper({ sx }: IProps) {
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation('features')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'help-center-popper' : undefined

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box alignSelf="stretch">
        <Button
          aria-describedby={id}
          fullWidth={!smAndUp}
          disableRipple
          variant="outlined"
          onClick={handleClick}
          startIcon={<QuestionSign />}
          endIcon={
            <ExpandMoreIcon
              sx={{
                transform: open ? 'rotate(180deg)' : undefined,
                color: theme.palette.primary.dark,
              }}
            />
          }
          sx={{
            padding: { xs: '4px 20px' },
            height: '100%',
            alignItems: 'center',
            borderRadius: '6px',
            borderColor: theme.palette.primary.dark + '!important',
            '&:hover': { backgroundColor: theme.palette.primary.light },
            '& .MuiButton-endIcon': {
              fontSize: accordionIconSize,
            },
            ...sx,
          }}
        >
          <Typography variant="subtitle5" lineHeight={1} color="primary.dark">
            {t('app-bar.help-center.help')}
          </Typography>
        </Button>

        <StyledPopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <HelpCenterContent />
        </StyledPopper>
      </Box>
    </ClickAwayListener>
  )
}
