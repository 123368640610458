import { Box, Stack, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessIcon } from './assets/success-icon.svg'

interface IProps {
  sx?: SxProps
}

export const WithdrawalSuccessTitle = ({ sx }: IProps) => {
  const { t } = useTranslation('features')

  return (
    <Stack direction={'row'} gap={1.5} alignItems={'center'} sx={sx}>
      <Box width={{ xs: 32, md: 36 }}>
        <SuccessIcon width="100%" />
      </Box>

      <Typography variant={'subtitle4'}>{t('withdraw.success')}</Typography>
    </Stack>
  )
}
