import { IWallet } from '@common/types'

import { api } from '@shared/http-instance'

export async function fetchWalletList(accountId?: string) {
  if (!accountId) {
    return []
  }

  const url = `/accounts/${accountId}/wallets`

  const response = await api.get<IWallet[]>(url)

  return response.data
}
