import { DialogContent, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { PropsWithChildren, useCallback } from 'react'

import { scrollbarSx } from '@shared/ui'

import { CloseBtn } from './components'
import { StyledDialog, StyledDrawer } from './styled'

export interface IModalProps {
  onClose: () => void
  open: boolean
  loading?: boolean
  error?: boolean
  sx?: SxProps
}

export function CustomModal({
  open,
  onClose,
  loading,
  children,
  error,
  sx,
}: IModalProps & PropsWithChildren) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleClose = useCallback(!loading ? onClose : () => {}, [
    loading,
    onClose,
  ])

  return (
    <>
      {mdAndUp && (
        <StyledDialog
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={loading}
          sx={sx}
        >
          <CloseBtn onClose={onClose} disabled={loading} error={error} />

          <DialogContent sx={{ padding: '40px', ...scrollbarSx }}>
            {children}
          </DialogContent>
        </StyledDialog>
      )}

      {!mdAndUp && (
        <StyledDrawer
          disableEscapeKeyDown={loading}
          anchor="bottom"
          open={open}
          onClose={handleClose}
          ModalProps={{ BackdropProps: { onClick: handleClose } }}
          sx={sx}
        >
          <CloseBtn onClose={onClose} disabled={loading} error={error} />
          {children}
        </StyledDrawer>
      )}
    </>
  )
}
