import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  IconButton,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useMemo, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { deepBlue } from '@shared/constants'
import { useEscapeKeyListener } from '@shared/hooks'

import { UserMenuContent, AccountProfileButton } from './components'
import { popoverPaperSx, StyledMobileDrawer } from './styled'

export function UserMenu() {
  const { t } = useTranslation('features')
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEscapeKeyListener(handleClose)

  return (
    <>
      <AccountProfileButton
        id="account-profile-button"
        aria-controls={open ? 'account-profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />

      {smAndUp && (
        <Popover
          id="account-profile-menu"
          transitionDuration={0}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{ paper: { sx: popoverPaperSx } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted={true}
          sx={{ '& > .MuiPaper-root': { mt: 0.5 } }}
        >
          <UserMenuContent onClose={handleClose} />
        </Popover>
      )}

      {!smAndUp && (
        <StyledMobileDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          keepMounted={true}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle3">
              {t('app-bar.user-menu.mobile-title')}
            </Typography>

            <IconButton
              onClick={handleClose}
              sx={{ backgroundColor: deepBlue[50], p: 0.5 }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Stack>

          <UserMenuContent onClose={handleClose} />
        </StyledMobileDrawer>
      )}
    </>
  )
}
