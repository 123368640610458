import { AxiosError } from 'axios'

import { IUser } from '@common/types'

import { api } from '@shared/http-instance'
import keycloak from '@shared/keycloak'

export async function fetchUserInfo(): Promise<null | IUser> {
  try {
    const url = '/users/get-info'

    const response = await api.get<IUser>(url)

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      (error as AxiosError)?.response?.status === 404 ||
      (error as AxiosError)?.response?.status === 403
    ) {
      await keycloak.logout()
    }

    return null
  }
}
