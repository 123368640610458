import { LoadingButton } from '@mui/lab'
import { Grid, Typography } from '@mui/material'
import to from 'await-to-js'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TOnCloseMethod, TOtpResendCodeMethod } from '../../model'
import { ErrorText, ResendButton } from '../styled'

import { Timer } from './timer'

interface IProps {
  onTimerFinish?: () => void
  setShowResend: (value: boolean) => void
  reset: () => void
  setLoading: (value: boolean) => void
  resendCodeMethod?: TOtpResendCodeMethod
  handleClose: TOnCloseMethod
  loading: boolean
  showResend: boolean
  timer: number
  setIsTimeoutOver: Dispatch<SetStateAction<boolean>>
  isTimeoutOver: boolean
}

export function Actions({
  onTimerFinish,
  setShowResend,
  reset,
  resendCodeMethod,
  loading,
  setLoading,
  handleClose,
  showResend,
  timer,
  setIsTimeoutOver,
  isTimeoutOver,
}: IProps) {
  const [otpTimer, setOtpTimer] = useState(timer)
  const { t } = useTranslation('shared')

  useEffect(() => {
    let timerId: NodeJS.Timeout

    if (timer) {
      timerId = setTimeout(setShowResend, (timer * 1000) / 2, true)
    }

    return () => clearTimeout(timerId)
  }, [timer])

  const handleTimerFinish = () => {
    setIsTimeoutOver(true)

    reset()

    onTimerFinish && onTimerFinish()
  }

  const handleResendOtp = async () => {
    try {
      if (!resendCodeMethod) {
        // eslint-disable-next-line no-console
        return console.error('OTPDialog: resendCodeMethod is required')
      }

      setLoading(true)

      const [error, result] = await to(resendCodeMethod())

      setLoading(false)

      if (error || !result.result) {
        toast.error(t('otp dialog.errors.resendOtpCodeFail'))

        handleClose('resendOtpFail')

        return
      }

      setOtpTimer(result.ttl)

      toast.success(t('otp dialog.resendSuccess'))

      reset()

      setIsTimeoutOver(false)

      setShowResend(false)
    } catch {
      handleClose('resendOtpFail')
    }
  }

  const isShowResend = useMemo(
    () => showResend && resendCodeMethod,
    [showResend, resendCodeMethod]
  )

  return (
    <>
      {isTimeoutOver && (
        <>
          <ErrorText sx={{ mt: 4 }}>
            <Trans ns="shared" i18nKey="otp dialog.errors.otpCodeTimeout" />
          </ErrorText>

          {resendCodeMethod && (
            <ResendButton
              variant="outlined"
              loading={loading}
              onClick={handleResendOtp}
              sx={{ mt: 2 }}
            >
              <Trans ns="shared" i18nKey="otp dialog.resendOtpCode" />
            </ResendButton>
          )}
        </>
      )}

      {!isTimeoutOver && (
        <>
          <Timer
            timer={otpTimer}
            onFinish={handleTimerFinish}
            sx={{ mb: { xs: 3, md: 4.5 } }}
          />

          <Grid container spacing={2}>
            {isShowResend && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <LoadingButton
                  variant="outlined"
                  loading={loading}
                  type="submit"
                  fullWidth
                  onClick={handleResendOtp}
                  sx={{
                    maxWidth: 300,
                    p: { xs: '16px 16px', md: '12px 12px' },
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="body4">
                    <Trans ns="shared" i18nKey="otp dialog.resendOtpCode" />
                  </Typography>
                </LoadingButton>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={isShowResend ? 6 : 12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
                fullWidth
                sx={{
                  maxWidth: 300,
                  p: { xs: '16px 16px', md: '12px 12px' },
                  borderRadius: '8px',
                  border: '1px solid transparent',
                }}
              >
                <Typography variant="body4">
                  <Trans ns="common" i18nKey="confirm" />
                </Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
