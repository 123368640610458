import { Stack, Typography, useTheme } from '@mui/material'
import { SxProps } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { grey } from '@shared/constants'

import { ReactComponent as DoneRoundedIcon } from './icons/done-rounded.svg'
import { StyledChooseButton, StyledChip } from './styled'

interface IProps {
  icon: ReactElement
  text: string
  selectType?: 'accountDir' | 'coin'
  active?: boolean
  onClick?: () => void
  disabled?: boolean
  accountName?: string
  loading?: boolean
  inReview?: boolean
  sx?: SxProps
}

export function ChooseButton({
  icon,
  text,
  active,
  selectType,
  onClick,
  disabled,
  accountName,
  loading,
  inReview,
  sx,
}: IProps) {
  const { t } = useTranslation('common')
  const theme = useTheme()

  return (
    <StyledChooseButton
      active={active}
      selectType={selectType}
      onClick={onClick}
      disabled={disabled || inReview}
      loading={loading}
      sx={sx}
    >
      {!loading && (
        <>
          {accountName && !inReview && (
            <StyledChip size="small" icon={<DoneRoundedIcon />} />
          )}

          {inReview && (
            <StyledChip
              color="warning"
              variant="outlined"
              label={t('in-review')}
              size="small"
            />
          )}

          <Stack
            justifyContent="center"
            alignItems="center"
            className="icon"
            sx={{
              '& .wallet-type-svg path, circle': {
                stroke: accountName ? theme.palette.primary.main : grey[500],
              },
              [theme.breakpoints.down('md')]: {
                ...(!accountName && !selectType && { marginTop: '24px' }),
              },
            }}
          >
            {icon}
          </Stack>
        </>
      )}

      <Typography
        component={'span'}
        variant={'body17'}
        width={'100%'}
        whiteSpace={'nowrap'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        py={{ xs: 1, md: 0.5 }}
      >
        {accountName || text}
      </Typography>
    </StyledChooseButton>
  )
}
