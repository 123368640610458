import { useKeycloak } from '@react-keycloak/web'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { BalancesRoute } from '@pages/balances'
import { BusinessAccountApplicationRoutes } from '@pages/business-account-application'
import { CreateTransactionPageRoute } from '@pages/create-transaction'
import { DepositInfoRoute } from '@pages/deposit-page'
import { ErrorPageRoute } from '@pages/error'
import { ExchangeRoute } from '@pages/exchange'
import { ExchangeHistoryRoute } from '@pages/exchange-history'
import { MainPageRoute } from '@pages/main-page'
import { NoInternetPageRoute } from '@pages/no-internet'
// import { OpenCryptoAccountRoute } from '@pages/open-crypto-account'
import { PersonalAccountApplicationRoute } from '@pages/personal-account-application'
import { ProfilePageRoute } from '@pages/profile'
import { TechnicalPageRoute } from '@pages/technical-work'
import { TopUpCryptoWalletRoute } from '@pages/top-up-crypto-wallet'
import { TransactionListPageRoute } from '@pages/transactions'
import { UiKit } from '@pages/ui-kit'
import { WithdrawalPageRoute } from '@pages/withdrawal'

import { AppLoader } from '@entities/app-loader'

import { routeNamesPaths } from '@shared/constants'

import {
  ErrorElement,
  MainGeneralLayout,
  MainAccountLayout,
  MainWalletLayout,
  TechnicalLayout,
} from './layouts'

const router = createBrowserRouter([
  {
    path: routeNamesPaths.home,
    errorElement: <ErrorElement />,
    element: <Navigate replace to={routeNamesPaths.assets} />,
  },
  {
    element: <MainGeneralLayout />,
    errorElement: <ErrorElement />,
    children: [
      // OpenCryptoAccountRoute,
      PersonalAccountApplicationRoute,
      ...BusinessAccountApplicationRoutes,
      ProfilePageRoute,
    ],
  },
  {
    element: <MainWalletLayout />,
    errorElement: <ErrorElement />,
    children: [
      MainPageRoute,
      CreateTransactionPageRoute,
      ExchangeRoute,
      TopUpCryptoWalletRoute,
    ],
  },

  {
    element: <MainAccountLayout />,
    errorElement: <ErrorElement />,
    children: [WithdrawalPageRoute, DepositInfoRoute],
  },
  {
    element: <MainWalletLayout paddingVariant="list" />,
    errorElement: <ErrorElement />,
    children: [BalancesRoute, ExchangeHistoryRoute, TransactionListPageRoute],
  },
  {
    element: <TechnicalLayout />,
    errorElement: <ErrorElement />,
    children: [TechnicalPageRoute, NoInternetPageRoute, ErrorPageRoute],
  },
  {
    path: '*',
    errorElement: <ErrorElement />,
    element: <Navigate replace to={routeNamesPaths.home} />,
  },
  {
    path: 'ui-kit',
    errorElement: <ErrorElement />,
    element: <UiKit />,
  },
])

export function RouterViewer() {
  const { keycloak, initialized } = useKeycloak()

  if (!initialized || !keycloak.authenticated) {
    return <AppLoader showManually />
  }

  return <RouterProvider router={router} />
}
