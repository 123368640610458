import {
  ICreateWithdrawalBodyU,
  ICreateWithdrawalResponseU,
} from '@common/types'

import { api } from '@shared/http-instance'

export function createWithdrawal(
  body: Omit<ICreateWithdrawalBodyU, 'keycloakId' | 'userId'>
) {
  return api
    .post<ICreateWithdrawalResponseU>('/withdrawals', body)
    .then((res) => res.data)
}
