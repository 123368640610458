import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { EUserConfigKeys, ITermsAndPrivacyConfigValue } from '@common/types'

import { useWelcomeDialogStore } from '@features/welcome-dialog'

import { useAppLoaderStore } from '@entities/app-loader'
import { useWalletList, useWalletStore } from '@entities/wallet'

import { useAuth } from '@shared/auth'
import { routeNamesPaths } from '@shared/constants'
import { useErrorListener } from '@shared/error-handlers'
import { useNoInternet } from '@shared/hooks'
import { useAccountStore } from '@shared/store'

export function useMainLayout() {
  const location = useLocation()
  const setShowLoader = useAppLoaderStore((state) => state.setShow)
  const setOpenWelcomeDialog = useWelcomeDialogStore((state) => state.setOpen)
  const isApproved = useAccountStore((s) => s.isApproved)
  const account = useAccountStore((s) => s.account)

  useErrorListener()

  useNoInternet()

  const [userDetailLoaded, setUserDetailLoaded] = useState(false)

  const {
    store: { user, userIdvData },
    fetchUser,
    fetchUserDetailInfo,
  } = useAuth()

  const { keycloak } = useKeycloak()

  const setActiveWallet = useWalletStore((state) => state.setWallet)

  const { isFetched: isWalletsFetched, data: walletList } = useWalletList()

  const fetchUserDetailInfoAndHideLoader = async () => {
    if (!user) {
      return
    }

    if (user.idvStatus !== 'APPROVED') {
      setUserDetailLoaded(true)
    } else if (user.idvStatus === 'APPROVED' && !userIdvData) {
      await fetchUserDetailInfo()

      setTimeout(() => {
        setUserDetailLoaded(true)
      }, 50)
    }
  }

  useEffect(() => {
    if (!user) {
      setShowLoader(true)

      if (keycloak.authenticated) {
        if (user) {
          setShowLoader(false)
        }

        fetchUser()
      }
    }
  }, [])

  const isVerified = useMemo(
    () =>
      user?.idvStatus === 'APPROVED' ||
      location.pathname === routeNamesPaths.profile,
    [user, location]
  )

  const isBlocked = useMemo(() => {
    return !!user?.configs.find(
      (config) => config.key === EUserConfigKeys.STATUSES
    )?.value.common.blocked
  }, [user])

  useEffect(() => {
    user && setOpenWelcomeDialog(!isVerified)

    fetchUserDetailInfoAndHideLoader()
  }, [user, isVerified])

  const isWalletList = useMemo(() => {
    return !!walletList?.length
  }, [walletList])

  useEffect(() => {
    if (
      account?.id && isApproved[account.id]
        ? isWalletsFetched && userDetailLoaded
        : userDetailLoaded
    ) {
      setShowLoader(false)
    }
  }, [isWalletsFetched, userDetailLoaded])

  useEffect(() => {
    if (walletList) {
      setActiveWallet(walletList[0])
    }
  }, [walletList])

  const blockedByTermsAndPrivacy = useMemo(() => {
    const isBlocked = user?.configs.find(
      (config) => config.key === EUserConfigKeys.TERMS_AND_PRIVACY
    )?.value.blocked

    return {
      blocked: !!isBlocked,
    } as ITermsAndPrivacyConfigValue
  }, [user])

  return {
    isVerified,
    userDetailLoaded,
    isWalletList,
    blockedByTermsAndPrivacy,
    isBlocked,
  }
}
