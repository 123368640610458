import { Stack, SxProps, Typography } from '@mui/material'
import { useMemo } from 'react'

import { IFeePlan } from '@common/types'

interface IProps {
  feePlan?: IFeePlan
  size?: 'small' | 'medium'
  sx?: SxProps
}

export function FeeInfo({ feePlan, size, sx }: IProps) {
  const isMedium = useMemo(() => size === 'medium', [size])

  return (
    <Stack gap={isMedium ? 1 : 0.5} p={isMedium ? 0 : 0.5} sx={sx}>
      {feePlan?.scales.map((rule, index) => (
        <Stack direction="row" justifyContent="space-between" key={rule.min}>
          <Typography
            variant={isMedium ? 'body14' : 'body19'}
            color={isMedium ? 'text.primary' : 'text.secondary'}
            component="span"
          >
            {`${rule.min}${rule.max ? ` - ${rule.max}` : ''} ${feePlan.currency}:`}
          </Typography>

          <Typography variant={isMedium ? 'body14' : 'body18'} component="span">
            {rule.percents}%{' '}
            {index === 0 ? `(min. ${feePlan.minFee} ${feePlan.currency})` : ''}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}
