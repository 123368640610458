import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'

import { fetchUploadFileSettings } from '../api'

export function useUploadFileSettings(enabled = false) {
  return useQuery({
    queryKey: [queryKeys.uploadFileSettings],
    queryFn: fetchUploadFileSettings,
    enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}
