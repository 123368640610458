import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledSecondWarningBlock = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    padding: '20px',

    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    backgroundColor: grey[50],
    borderRadius: '10px',
  },
}))
