import { SwapVert } from '@mui/icons-material'
import { Skeleton, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'

import { useAccountList } from '@entities/account'

import { useAccountStore } from '@shared/store'

import { AccountSwitcherStyledButton } from './styled'

export function AccountSwitcherButton() {
  const theme = useTheme()
  const [rotate, setRotate] = useState(true)
  const { data, isLoading } = useAccountList()
  const activeAccount = useAccountStore((state) => state.account)
  const setActiveAccount = useAccountStore((s) => s.setAccount)

  const switchAccountHandler = () => {
    if (data && activeAccount) {
      const account =
        data.find((element) => element.id !== activeAccount.id) || null

      setActiveAccount(account)

      setRotate((state) => !state)
    }
  }

  const isDisabled = useMemo(() => !(data && data.length > 1), [data])

  if (isLoading) {
    return <Skeleton sx={{ width: 32, height: 32 }} />
  }

  return (
    <AccountSwitcherStyledButton
      disabled={isDisabled}
      onClick={switchAccountHandler}
    >
      <SwapVert
        sx={{
          transform: `rotate(${rotate ? 0 : 180}deg)`,
          transition: theme.transitions.create(['transform']),
        }}
      />
    </AccountSwitcherStyledButton>
  )
}
