import to from 'await-to-js'
import { AxiosError } from 'axios'
import { memo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ISingleErrorResponse } from '@common/types'

import {
  transactionVerifyOtp,
  transactionSendOtp,
  ITransactionForm,
} from '@entities/transaction'

import { TConfirmationOptions } from '@shared/types'
import {
  OtpDialog,
  TOtpConfirmationMethod,
  TOtpConfirmationStatuses,
  TOtpResendCodeMethod,
} from '@shared/ui'
import { getTimeToLeftFromISODate } from '@shared/utils'

interface IProps {
  confirmationOptions: TConfirmationOptions
  onClose: () => void
  onConfirm: () => void
  setConfirmationOptions: (options: TConfirmationOptions) => void
  open: boolean
}

export const CreateTransactionOtp = memo(function CreateTransactionOtp({
  open,
  onClose,
  confirmationOptions,
  onConfirm,
  setConfirmationOptions,
}: IProps) {
  const [attemptsCounter, setAttemptsCounter] = useState<number>(0)

  const { t } = useTranslation('features')

  const { reset, getValues } = useFormContext<ITransactionForm>()

  const handleResetForm = () => {
    reset({
      receiverAddress: '',
      amount: '',
      balanceId: getValues('balanceId'),
    })
  }

  const otpErrorResolver = (error: unknown): TOtpConfirmationStatuses => {
    switch (error) {
      case 'This code is invalid!':
        return 'NOT_VALID'
      case 'SMS confirmation timeout':
        return 'EXPIRE'
      default:
        throw new Error('Something went wrong. Please try-again later.')
    }
  }

  const confirmationMethod: TOtpConfirmationMethod = async ({ otp }) => {
    if (!confirmationOptions) {
      throw new Error('Crypto transaction is not defined')
    }

    const [error] = await to(
      transactionVerifyOtp({
        transactionId: confirmationOptions.id,
        otpCode: otp,
      })
    )

    if (!error) {
      return 'SUCCESS'
    }

    return otpErrorResolver(
      (error as AxiosError<ISingleErrorResponse>).response?.data.error
    )
  }

  const handleConfirm = () => {
    toast.success(t('transaction.toast-messages.success'))

    onClose()

    handleResetForm()

    setTimeout(onConfirm, 300)
  }

  const resendOtp: TOtpResendCodeMethod = async () => {
    if (!confirmationOptions) {
      throw new Error('Crypto transaction is not defined')
    }

    const [error, response] = await to(
      transactionSendOtp({ transactionId: confirmationOptions.id })
    )

    setAttemptsCounter((prev) => prev + 1)

    if (error || !response) {
      toast.error(t('transaction.toast-messages.failed-to-send'))

      return { result: false, ttl: 0 }
    }

    const ttl = getTimeToLeftFromISODate(response.confirmation.end)

    setConfirmationOptions({
      id: confirmationOptions.id,
      attempts: response.confirmation.tries,
      end: ttl,
    })

    return {
      result: true,
      ttl,
    }
  }

  const handleCloseOtp = () => {
    handleResetForm()

    onClose()
  }

  return (
    <OtpDialog
      show={open}
      onClose={handleCloseOtp}
      confirmationMethod={confirmationMethod}
      onConfirm={handleConfirm}
      resendCodeMethod={
        attemptsCounter >= (confirmationOptions?.attempts || 1)
          ? undefined
          : resendOtp
      }
      timer={confirmationOptions?.end}
    />
  )
})
