import { Stack, SxProps, Typography } from '@mui/material'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFiatBalances } from '@entities/fiat-balance'

import { useAccountStore } from '@shared/store'
import { formatEurNumber } from '@shared/utils'

interface IProps {
  sx?: SxProps
  toCryptoButton?: ReactNode
}

export function AccountHeaderData({ toCryptoButton, sx }: IProps) {
  const activeAccount = useAccountStore((state) => state.account)

  const { data: fiatBalances } = useFiatBalances(activeAccount?.id)

  const { t } = useTranslation('entities')

  const availableAmount = useMemo(() => {
    const available = fiatBalances
      ?.find((balance) => balance.currency === 'EUR')
      ?.available.toString()

    return formatEurNumber(available)
  }, [fiatBalances])

  return (
    <Stack gap={1} sx={sx}>
      <Typography variant={'h1'} fontSize={18} fontWeight={500}>
        {activeAccount?.variant === 'PERSONAL'
          ? t('virtual-account.title-personal')
          : t('virtual-account.title-business')}
      </Typography>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant={'numeric1'} fontSize={24} fontWeight={200}>
          {`${availableAmount} EUR`}
        </Typography>

        {toCryptoButton}
      </Stack>
    </Stack>
  )
}
