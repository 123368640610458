import { Skeleton } from '@mui/material'
import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CustomModal,
  ModalTitleWithWarning,
  OperationInfoList,
} from '@shared/ui'

import { IExchangeDetailsModalRef, useExchange } from '../model'

export const ExchangeDetailsModal = forwardRef<IExchangeDetailsModalRef>(
  function ExchangeDetailsModal(_, ref) {
    const { t } = useTranslation('widgets')

    const {
      exchangeDetailsForRender,
      exchangeId,
      handleClose,
      handleOpen,
      isLoading,
      isOpen,
    } = useExchange()

    useImperativeHandle(ref, () => ({
      handleOpen,
    }))

    return (
      <CustomModal
        onClose={handleClose}
        open={isOpen}
        sx={{
          '& .MuiDialogContent-root': {
            paddingBottom: { md: '36px' },
          },
        }}
      >
        <ModalTitleWithWarning
          title={t('exchange.details.title')}
          id={exchangeId || ''}
          copyButton
          sx={{ mb: 2 }}
        />

        {!isLoading ? (
          <OperationInfoList operationInfo={exchangeDetailsForRender} />
        ) : (
          <Skeleton height={64} sx={{ transform: 'initial' }} />
        )}
      </CustomModal>
    )
  }
)
