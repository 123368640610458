export const queryKeys = {
  walletList: 'walletList',
  accounts: 'accounts',
  rates: 'rates',
  exchangeList: 'exchangeList',
  exchangeDetails: 'exchangeDetails',
  transactions: 'cryptoTransactions',
  withdrawalList: 'withdrawalList',
  depositList: 'depositList',
  depositDetails: 'depositDetails',
  depositLimits: 'depositLimits',
  transactionSettings: 'transactionSettings',
  exchangeSettings: 'exchangeSettings',
  applications: 'applications',
  feePlans: 'feePlans',
  withdrawalDetails: 'withdrawalDetails',
  transactionDetails: 'transactionDetails',
  fiatBalances: 'fiatBalances',
  cryptoBalances: 'cryptoBalances',
  uploadFileSettings: 'uploadFileSettings',
  withdrawalSettings: 'withdrawalSettings',
} as const
