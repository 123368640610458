import { Box } from '@mui/material'
import { format } from 'date-fns'
import Decimal from 'decimal.js'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IExchangeDetails } from '@common/types'

import { useExchangeStatusColors } from '@entities/exchange'

import { IOperationInfo } from '@shared/ui'

export function useExchangeForRender(exchangeDetails?: IExchangeDetails) {
  const { t } = useTranslation(['entities', 'shared'])

  const colors = useExchangeStatusColors()

  const exchangeDetailsForRender: IOperationInfo[] = useMemo(() => {
    if (!exchangeDetails) {
      return []
    }

    const {
      buyingAmount,
      buyingCurrency,
      createdAt,
      fee,
      rateFinal,
      sellingAmount,
      sellingCurrency,
      status,
      type,
    } = exchangeDetails

    const isSell = type === 'SELL'

    const fiatBuyingAmountWithFee = new Decimal(
      isSell ? buyingAmount : sellingAmount
    )
      .add(fee)
      .toFixed(2)

    const fullAmount = isSell
      ? Number(sellingAmount).toFixed(6)
      : fiatBuyingAmountWithFee

    return [
      {
        keyName: t('exchange.details.type'),
        value: `${t(`exchange.details.${type}`)} ${
          isSell ? sellingCurrency : buyingCurrency
        }`,
      },
      {
        keyName: t('exchange.details.status'),
        value: (
          <Box component="span" color={colors[status]}>
            {t(`entities:exchange.statuses.${status}`)}
          </Box>
        ),
      },
      {
        keyName: t('exchange.details.selling-amount'),
        value: `${fullAmount} ${sellingCurrency}`,
      },
      {
        keyName: t('exchange.details.arbiex-fee'),
        value: `${Number(fee).toFixed(2)} ${
          isSell ? buyingCurrency : sellingCurrency
        }`,
      },
      {
        keyName: t(
          `exchange.details.${!isSell ? 'amount-for-exchange' : 'amount-after-exchange'}`
        ),
        value: `${
          !isSell ? Number(sellingAmount).toFixed(2) : fiatBuyingAmountWithFee
        } ${isSell ? buyingCurrency : sellingCurrency}`,
      },
      {
        keyName: t('exchange.details.final-rate'),
        value: rateFinal,
      },
      {
        keyName: t(
          `exchange.details.${!isSell ? 'buying-amount' : 'crediting-buying-amount'}`
        ),
        value: `${buyingAmount} ${buyingCurrency}`,
      },
      {
        keyName: t('exchange.details.date'),
        value: format(new Date(createdAt), 'dd.MM.yyyy HH:mm'),
      },
    ]
  }, [exchangeDetails])

  return exchangeDetailsForRender
}
