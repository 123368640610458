import { idvLink } from './env-constants'

export const personalApplicationMainPath = '/accounts/application/personal'
export const businessApplicationMainPath = '/accounts/application/business'

export const routeNamesPaths = {
  errorPage: '/error',
  tradingCrypto: '/trading-crypto',
  home: '/',
  withdrawal: '/withdrawal',
  deposit: '/deposit',
  createTransaction: '/send-transaction',
  receive: '/receive',
  exchange: '/exchange',
  exchangeHistory: '/exchange-history',
  transactions: '/transactions',
  openCryptoAccount: '/open-account',
  assets: '/assets',
  techWork: '/tech-work',
  noInternet: '/no-internet',
  profile: '/profile',

  // personal account application
  personalApplication: `${personalApplicationMainPath}`,
  paPersonalDetail: `${personalApplicationMainPath}/personal-details`,
  paExpectedTurnover: `${personalApplicationMainPath}/expected-turnover`,
  paIdentityDocument: `${personalApplicationMainPath}/identity-document`,
  paHomeAddress: `${personalApplicationMainPath}/home-address`,
  paTerms: `${personalApplicationMainPath}/terms-and-conditions`,
  paFinish: `${personalApplicationMainPath}/finish`,

  // business account application
  businessApplication: `${businessApplicationMainPath}/general/customer`,
  baGeneral: `${businessApplicationMainPath}/general/:subStep`, //customer, address, contact
  baTurnover: `${businessApplicationMainPath}/turnover`,
  baDirectors: `${businessApplicationMainPath}/directors`,
  baDirectorsWithParam: `${businessApplicationMainPath}/directors/:subStep`,
  baEditDirector: `${businessApplicationMainPath}/directors/:index/:subStep`,
  baShareholders: `${businessApplicationMainPath}/shareholders`,
  baEditShareholder: `${businessApplicationMainPath}/shareholders/:index/:subStep`,
  baTerms: `${businessApplicationMainPath}/terms`,
  baSuccess: `${businessApplicationMainPath}/success`,
} as const

type TKeys = keyof typeof routeNamesPaths

export type TRouteNamesPaths = (typeof routeNamesPaths)[TKeys]

export const externalLinks = {
  landingContacts: 'https://arbiex.eu/contact-us',
  privacyPolicyArbiex: 'https://arbiex.eu/privacy-policy',
  termsAndConditions: 'https://arbiex.eu/service-agreement',
  idv: idvLink,
  support: 'mailto:info@arbiex.eu',
}
