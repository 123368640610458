import { Box } from '@mui/material'
import { useMemo } from 'react'

import { IAccountU } from '@common/types'

import { useAccountList } from '@entities/account'
import { useFiatBalances } from '@entities/fiat-balance'

import { useAccountStore } from '@shared/store'
import { tabIndexKeyUpCallback } from '@shared/utils'

import { AmountAndType } from './amount-and-type'
import { ChooseAccountButton, CustomCheckBox } from './styled'

type TAccountIds = Record<IAccountU['variant'], string>

export function AccountChooser() {
  const activeAccount = useAccountStore((s) => s.account)
  const setActiveAccount = useAccountStore((s) => s.setAccount)

  const { data: accountList } = useAccountList()

  const accountIds: TAccountIds | undefined = useMemo(() => {
    let result: undefined | TAccountIds

    if (accountList) {
      result = {} as TAccountIds

      accountList.forEach((account) => {
        if (result) {
          result[account.variant] = account.id
        }
      })
    }

    return result
  }, [accountList])

  const { data: personalFiatBalances } = useFiatBalances(accountIds?.PERSONAL)
  const { data: businessFiatBalances } = useFiatBalances(accountIds?.BUSINESS)

  const personalAvailableAmount = useMemo(() => {
    return personalFiatBalances?.find((balance) => balance.currency === 'EUR')
      ?.available
  }, [personalFiatBalances])

  const businessAvailableAmount = useMemo(() => {
    return businessFiatBalances?.find((balance) => balance.currency === 'EUR')
      ?.available
  }, [businessFiatBalances])

  return (
    <Box component="ul">
      {accountList?.map((account) => (
        <ChooseAccountButton
          variant="outlined"
          component="li"
          disableFocusRipple
          key={account.id}
          onClick={() => setActiveAccount(account)}
          fullWidth
          onKeyUp={tabIndexKeyUpCallback(() => setActiveAccount(account))}
        >
          <AmountAndType
            type={account?.variant}
            amount={
              account?.variant === 'PERSONAL'
                ? personalAvailableAmount
                : businessAvailableAmount
            }
          />

          <CustomCheckBox selected={activeAccount?.id === account.id} />
        </ChooseAccountButton>
      ))}
    </Box>
  )
}
