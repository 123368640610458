import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { fetchWalletList } from '@entities/wallet'

import { queryKeys } from '@shared/constants'
import { useAccountStore } from '@shared/store'

import { useWalletStore } from './use-wallet-store'

export function useWalletList() {
  const currAccount = useAccountStore((acc) => acc.account)
  const isApproved = useAccountStore((state) => state.isApproved)

  const wallet = useWalletStore((state) => state.wallet)
  const setWallet = useWalletStore((state) => state.setWallet)

  const query = useQuery({
    queryKey: [queryKeys.walletList, currAccount?.id],
    queryFn: () => fetchWalletList(currAccount?.id),
    refetchInterval: 5 * 60 * 5000,
    enabled: !!currAccount?.id && isApproved[currAccount.id],
  })

  useEffect(() => {
    if (query.data && wallet) {
      const found = query.data.find((w) => w.id === wallet.id)

      setWallet(found ? found : query.data[0])
    }
  }, [query.data])

  return query
}
